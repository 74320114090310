export default class IosCalendarCreative {
  description = ''
  id = null
  isActive = true
  title = ''
  url = ''

  constructor(data) {
    if (!data) return

    this.description = data.description ?? ''
    this.id = data.id ?? null
    this.isActive = data.is_active ?? true
    this.title = data.title ?? ''
    this.url = data.url ?? ''
  }
}
