export default {
  SET_TRAFFIC_CHART_IS_LOADING: 'SET_TRAFFIC_CHART_IS_LOADING',
  SET_TRAFFIC: 'SET_TRAFFIC',
  SET_COUNTRIES_IDS: 'SET_COUNTRIES_IDS',
  SET_PRICING_MODEL: 'SET_PRICING_MODEL',
  SET_TRAFFIC_TYPE_ID: 'SET_TRAFFIC_TYPE_ID',
  SET_SELECT_ROTATION_AD_TYPE: 'SET_SELECT_ROTATION_AD_TYPE',
  SET_AD_FORMAT_ID: 'SET_AD_FORMAT_ID',
  SET_IS_PREMIUM_SITES: 'SET_IS_PREMIUM_SITES',
  SET_PREMIUM_SITES_BY_FORMAT: 'SET_PREMIUM_SITES_BY_FORMAT',
  SET_PREMIUM_SITES_IDS: 'SET_PREMIUM_SITES_IDS',
  SET_OPERATING_SYSTEMS_IDS: 'SET_OPERATING_SYSTEMS_IDS',
  SET_COUNTRY_PRESET: 'SET_COUNTRY_PRESET',
  SET_PREMIUM_SITES_IS_LOADING: 'SET_PREMIUM_SITES_IS_LOADING'
}
