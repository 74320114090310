import http from '../http.js'

export default {
  async table(params) {
    this.table.abort?.()
    const { data } = await http.get('/api/private/v1/traffic-providers', {
      params,
      cancelToken: new http.CancelToken((abort) => {
        this.table.abort = abort
      })
    })
    return data
  },
  async billingTable(params) {
    this.billingTable.abort?.()
    const { data } = await http.get('/api/private/v1/traffic-provider-subscription-statistics', {
      params,
      cancelToken: new http.CancelToken((abort) => {
        this.billingTable.abort = abort
      })
    })
    return data
  },
  store: async (params) => {
    const { data } = await http.post('/api/private/v1/traffic-providers', params)
    return data
  },
  activate: async (providerId) => {
    await http.post(`/api/private/v1/traffic-providers/${providerId}/activate`)
  },
  pause: async (providerId) => {
    await http.post(`/api/private/v1/traffic-providers/${providerId}/pause`)
  },
  delete: async (providerId) => {
    await http.delete(`/api/private/v1/traffic-providers/${providerId}`)
  },
  async list(params) {
    this.list.abort?.()
    const { data } = await http.get('/api/private/v1/traffic-providers/collection', {
      params,
      cancelToken: new http.CancelToken((abort) => {
        this.list.abort = abort
      })
    })
    return data
  },
  async sspFormats() {
    const { data } = await http.get('/api/private/v1/traffic-provider-ssp-formats')
    return data
  }
}
