import actions from '@/store/trafficChart/common/actions.js'
import mutations from '@/store/trafficChart/common/mutations.js'
import getters from '@/store/trafficChart/common/getters.js'
import state from '@/store/trafficChart/common/state.js'

const trafficChartModule = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default trafficChartModule
