import AdaptiveStringTable from '@/components/AdaptiveStringTable.vue'

export default {
  install(_Vue) {
    const components = {
      AdaptiveStringTable
    }

    Object.entries(components).forEach(([name, component]) => {
      _Vue.component(name, component)
    })
  }
}
