import moment from 'moment'
import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
import http from '../http.js'
import PaymentMethod from '@/services/classes/PaymentMethod.js'

export default {
  list: async () => {
    const { data } = await http.get('/api/private/v1/finances/payments')
    return data.data.map((item) => ({
      id: item.id,
      amount: toFixedByDefault(+item.amount),
      createdAt: moment(item.created_at).format('DD.MM.YYYY HH:mm'),
      currency: item.currency,
      formParams: item.form_params,
      metadata: {
        isSubscription: item.metadata?.is_subscription
      },
      number: item.number,
      paidAt: item.paid_at ? moment(item.paid_at).format('DD.MM.YYYY HH:mm') : null,
      paymentMethod: new PaymentMethod(item.payment_method),
      isDownloadable: item.is_downloadable
    }))
  }
}
