import http from '../http.js'

export default {
  store: async () => {
    await http.post('/api/private/v1/api-token')
  },
  destroy: async ({ token }) => {
    await http.delete(`/api/private/v1/api-token/${token}`)
  },
  swaggerApi: async () => {
    const { data } = await http.get('/api/private/v1/docs/api.json')
    return data
  }
}
