export default class CampaignTargets {
  browserTarget = []
  carrierTarget = {
    type: '',
    carriers: []
  }
  categoryGroupTarget = []
  categoryTarget = []
  providersTarget = {
    enableMainProvider: true,
    selectedProviders: []
  }
  cityTarget = []
  countryTarget = []
  deviceModelTarget = []
  deviceVendorTarget = []
  interestTarget = []
  ip2LocationTypeTarget = []
  ipRangesTarget = {}
  ipv6Target = {
    accept: true
  }
  languageTarget = []
  mobileAppTarget = {
    accept: null,
    mobileApps: []
  }
  operatingSystemTarget = []
  placementTypeTarget = []
  regionTarget = []
  siteTarget = {
    accept: null,
    premium: null,
    sites: []
  }
  spotTarget = {
    accept: true,
    spots: []
  }
  throttleTarget = null
  trafficTypeTarget = {}
  uniquesTarget = {}
  webPushSubscriptionTimeTarget = {}
  trafficQualityType = null
  campaignTrafficPlatformTarget = {
    accept: null,
    isLocked: false,
    campaignTrafficPlatforms: []
  }

  constructor(data) {
    if (!data) return

    this.campaignTrafficPlatformTarget = {
      accept: data.campaign_traffic_platform_target?.accept ?? null,
      isLocked: data.campaign_traffic_platform_target?.is_locked ?? false,
      campaignTrafficPlatforms: this.createTrafficPlatforms(data.campaign_traffic_platform_target?.campaign_traffic_platforms)
    }
    this.inPageTrafficTarget = data.in_page_traffic_target?.accept ?? false
    this.trafficQualityType = data.traffic_quality_target?.quality ?? null
    this.ipv6Target.accept = data.ipv6_target?.accept ?? true
    this.throttleTarget = data.throttle_target?.smooth_spend_rate ?? null
    this.webPushSubscriptionTimeTarget = {
      maxDays: data.web_push_subscription_time_target?.max_days ?? null,
      minDays: data.web_push_subscription_time_target?.min_days ?? null
    }
    this.categoryGroupTarget = this.createTarget(data.category_group_target?.category_groups)
    this.countryTarget = this.createTarget(data.country_target?.countries)
    this.providersTarget = {
      enableMainProvider: data.traffic_provider_target?.enable_main_provider ?? true,
      selectedProviders: this.createTarget(data.traffic_provider_target?.traffic_providers)
    }
    this.deviceVendorTarget = this.createTarget(data.device_vendor_target?.device_vendors)
    this.interestTarget = this.createTarget(data.interest_target?.interests)
    this.ip2LocationTypeTarget = this.createTarget(
      data.ip2_location_type_target?.ip2_location_types
    )
    this.languageTarget = this.createTarget(data.language_target?.languages)
    this.placementTypeTarget = this.createTarget(data.placement_type_target?.placement_types)

    this.mobileAppTarget = this.createMobileAppTarget(data.mobile_app_target)
    this.siteTarget = this.createSiteTarget(data.site_target)
    this.spotTarget = {
      accept: data.spot_target?.accept ?? null,
      spots: data.spot_target?.spots?.map((spot) => ({
        id: spot.tds_id,
        tdsId: spot.tds_id
      })) ?? []
    }
    this.uniquesTarget = this.createUniquesTarget(data.uniques_target)

    this.carrierTarget = {
      type: data.carrier_target?.type ?? '',
      carriers: this.createTarget(data.carrier_target?.carriers)
    }

    this.trafficTypeTarget = {
      id: data.traffic_type_target?.traffic_type.id,
      name: data.traffic_type_target?.traffic_type.name,
      boss_tds_os_types: data.traffic_type_target?.traffic_type.boss_tds_os_types
    }

    this.browserTarget = data.browser_target?.browsers.map((browser) => ({
      id: browser.id,
      name: browser.name,
      version: browser.version
    })) ?? []

    this.operatingSystemTarget = data.operating_system_target?.operating_systems.map((system) => ({
      id: system.id,
      name: system.name,
      version: system.version
    })) ?? []

    this.categoryTarget = data.category_target?.categories.map((category) => ({
      id: category.id,
      name: category.name,
      categoryGroupId: category.category_group_id
    })) ?? []

    this.cityTarget = data.city_target?.cities.map((city) => ({
      id: city.id,
      name: city.name,
      region_id: city.region_id
    })) ?? []

    this.deviceModelTarget = data.device_model_target?.device_models.map((deviceModel) => ({
      id: deviceModel.id,
      name: deviceModel.name,
      device_vendor_id: deviceModel.device_vendor_id
    })) ?? []

    this.ipRangesTarget = {
      accept: data.ip_ranges_target?.accept ?? false,
      ranges: data.ip_ranges_target?.ranges ?? ''
    }

    this.regionTarget = data.region_target?.regions.map((region) => ({
      id: region.id,
      name: region.name,
      country_id: region.country_id
    })) ?? []
  }

  createTarget(targets) {
    return (
      targets?.map((target) => ({
        id: target.id,
        name: target.name
      })) ?? []
    )
  }

  createTrafficPlatforms(platforms) {
    return (
      platforms?.map((platform) => ({
        id: platform.id,
        name: platform.name,
        systemIds: platform.system_ids
      })) ?? []
    )
  }

  createMobileAppTarget(mobileAppTarget) {
    return {
      accept: mobileAppTarget?.accept,
      mobileApps:
        mobileAppTarget?.mobile_apps.map((app) => ({
          id: app.id,
          name: app.name,
          platform: app.platform
        })) ?? []
    }
  }

  createSiteTarget(siteTarget) {
    return {
      accept: siteTarget?.accept ?? null,
      premium: siteTarget?.premium ?? null,
      sites:
        siteTarget?.sites.map((site) => ({
          billingDomain: site.billing_domain,
          domain: site.domain,
          id: site.id,
          isNew: site.is_new,
          isMarketplace: site.is_marketplace,
          premium: site.premium,
          tubecorp: site.tubecorp
        })) ?? []
    }
  }

  createUniquesTarget(uniquesTarget) {
    return {
      accept: uniquesTarget?.accept ?? null,
      cap: uniquesTarget?.cap ?? null,
      ttl: uniquesTarget?.ttl ?? null
    }
  }
}
