import { datatableFormater } from '@clickadilla/datatable-formatter'
import http from '../http.js'

export default {
  async index() {
    const { data } = await http.get('/api/private/v1/referral-program')
    return data.data
  },

  async referralTable(params) {
    this.referralTable.abort?.()

    const response = await http.get('/api/private/v1/referral-program/referrals', {
      params: datatableFormater(params),
      cancelToken: new http.CancelToken((abort) => {
        this.referralTable.abort = abort
      })
    })

    return {
      items: response.data.data,
      totalItemsCount: response.data.recordsFiltered
    }
  },

  async statistics(formData) {
    this.statistics.abort?.()
    const { data } = await http.get('/api/private/v1/referral-program/statistics', {
      params: {
        ...datatableFormater(formData),
        startDate: formData.startDate,
        endDate: formData.endDate,
        groupBy: formData.groupBy
      },
      cancelToken: new http.CancelToken((abort) => {
        this.abort = abort
      })
    })

    return data
  }
}
