var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',{class:[
    'd-flex flex-wrap mx-4 px-0 py-0',
    _vm.isMiniSidebarShown ? 'flex-column align-center pa-0 mb-0' : 'mb-4'
  ],attrs:{"flat":""}},_vm._l((_vm.supportMenu),function(item,key){return _c('v-tooltip',{key:key,attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',_vm._g(_vm._b({class:[
            'd-flex pa-0 min-height-auto flex-unset',
            _vm.isMiniSidebarShown ? 'mb-5 mr-0' : 'mr-5 mt-2 mb-4'
          ],attrs:{"href":item.href},on:{"click":function($event){item.liveChat ? _vm.liveChatHandler() : null}}},'v-list-item',_vm.isTooltipShown(attrs),false),_vm.isTooltipShown(on)),[_c('v-icon',{class:{ 'primary--text': hover },attrs:{"size":"18"}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1)]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.text))])])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }