import http from '../http.js'
import ValidationError from '@/services/classes/validation-error.js'
import formatIpRanges from '@/services/utils/format-ip-ranges-string.js'

export default {
  parse: async (ipRanges) => {
    try {
      const { data } = await http.post('/api/private/v1/ip-ranges/collections', {
        ip_ranges: formatIpRanges(ipRanges)
      })
      return data
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        throw new ValidationError({
          ipRanges: error.response.data.errors.ip_ranges
        })
      }
      throw error
    }
  }
}
