import Vue from 'vue'
import { GTM_EVENTS, GTM_EVENTS_CATEGORY } from '@clickadilla/components/constants/gtm-events.js'
import gtmPush from '@/services/utils/gtm-push.js'

export default {
  install(vue) {
    vue.prototype.$pwa = Vue.observable({
      launchMode: (() => {
        let launchMode = 'tab'

        if (navigator.standalone) {
          launchMode = 'standalone-ios'
        }

        if (window.matchMedia('(display-mode: standalone)').matches) {
          launchMode = 'standalone'
        }

        return launchMode
      })(),

      prompt: null,
      hasPrompt: false
    })

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      vue.prototype.$pwa.hasPrompt = true
      vue.prototype.$pwa.prompt = () => {
        e.prompt().then((userChoice) => {
          if (userChoice.outcome === 'accepted') {
            gtmPush({ event: GTM_EVENTS.PWA_INSTALL, event_category: GTM_EVENTS_CATEGORY.PWA })
          }
        })
      }
    })

    window.addEventListener('appinstalled', () => {
      window.location.reload()
    })
  }
}
