import actions from '@/store/dashboard/common/actions.js'
import mutations from '@/store/dashboard/common/mutations.js'
import getters from '@/store/dashboard/common/getters.js'
import state from '@/store/dashboard/common/state.js'

const dashboardModule = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default dashboardModule
