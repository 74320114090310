export default {
  getRequestPersonal(state) {
    return {
      name: state.name,
      account_type: state.accountType,
      company_name: state.companyName,
      company_address: state.companyAddress,
      company_site: state.companySite,
      vat: state.vat,
      phone: state.phone,
      additional_info: state.additionalInfo,
      notifications_about_ad_actions: state.adActionsNotifications,
      notifications_about_campaign_actions: state.campaignActionsNotifications,
      notifications_about_campaign_tooltips: state.campaignTooltipsNotifications,
      two_factor_authentication_method: state.twoFactorAuthenticationMethod,
      messengers: state.userMessengers.map((messenger) => ({ nickname: messenger.nickname, messenger_id: messenger.id }))
    }
  },
  accountTypeIsPersonal: (state) => state.accountType === state.accountTypes.personal,
  accountTypeIsCompany: (state) => state.accountType === state.accountTypes.company,
  userNameOrCompanyName: (state, getters) => (getters.accountTypeIsCompany ? state.companyName : state.name),
  userHasProviderSubscription: (state) => !!state.trafficProviderSubscription.id
}
