import { datatableFormater } from '@clickadilla/datatable-formatter'
import http from '../http.js'
import CampaignGroup from '@/services/classes/CampaignGroup.js'

export default {
  index: async () => {
    const { data } = await http.get('/api/private/v1/campaign-groups')
    return data.data.map((campaignGroup) => new CampaignGroup(campaignGroup))
  },
  table: async (params) => {
    const { data } = await http.get('/api/private/v1/campaign-groups', {
      params: datatableFormater(params)
    })
    return {
      items: data.data,
      totalItemsCount: data.recordsFiltered
    }
  },
  store: async (name) => {
    const { data } = await http.post('/api/private/v1/campaign-groups', { name })
    return { items: new CampaignGroup(data.data) }
  },
  edit: async ({ id, name }) => {
    const { data } = await http.put(`/api/private/v1/campaign-groups/${id}`, { name })
    return { items: new CampaignGroup(data.data) }
  },
  delete: async (id) => {
    const { data } = await http.delete(`/api/private/v1/campaign-groups/${id}`)
    return { items: new CampaignGroup(data.data) }
  },
  pushCampaignsToGroup: async ({ campaignGroupId, campaignsIds }) => {
    await http.post(`/api/private/v1/campaign-groups/${campaignGroupId}/campaigns`, {
      campaigns: campaignsIds
    })
  }
}
