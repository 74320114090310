import handleErrors from '@/services/handleErrors.js'
import { personalRepository } from '@/services/repository-factory.js'
import settings from '@/settings.js'

export default {
  install(_Vue) {
    const auth = new _Vue({
      data() {
        return {
          isAuth: false,
          user: null,
          authTokenKey: [settings.authTokenKey],
          refreshTimerId: null,
          refreshDelayMinutes: 30
        }
      },
      methods: {
        async refresh() {
          this.stopRefreshInterval()
          try {
            const response = await personalRepository.refresh()
            const newToken = response.headers.authorization.replace('Bearer ', '')
            localStorage.setItem(this.authTokenKey, newToken)
            this.isAuth = true
            this.startRefreshInterval()
          } catch (error) {
            handleErrors(error)
          }
        },
        async login(params) {
          const response = await personalRepository.login(params)
          const newToken = response.headers.authorization.replace('Bearer ', '')
          localStorage.setItem(this.authTokenKey, newToken)
          this.isAuth = true
          this.startRefreshInterval()
        },
        async register(params) {
          const { data } = await personalRepository.register(params)
          localStorage.setItem(this.authTokenKey, data.auth_token)
          this.isAuth = true
          this.startRefreshInterval()
          return data
        },
        logout() {
          this.removeToken()
        },
        async fetchUser() {
          try {
            this.user = await personalRepository.profile2()
          } catch (error) {
            handleErrors(error)
          }
        },
        getToken() {
          const token = localStorage.getItem(this.authTokenKey)
          if (!token || !this.validateToken(token)) {
            this.removeToken()
            return null
          }
          return token
        },
        validateToken(token) {
          // eslint-disable-next-line no-control-regex
          return !/[^\u0000-\u00ff]/g.test(token)
        },
        removeToken() {
          localStorage.removeItem(this.authTokenKey)
          this.isAuth = false
          this.user = null
          this.stopRefreshInterval()
        },
        startRefreshInterval() {
          this.refreshTimerId = setInterval(this.refresh, 1000 * 60 * this.refreshDelayMinutes)
        },
        stopRefreshInterval() {
          clearInterval(this.refreshTimerId)
          this.refreshTimerId = null
        }
      }
    })

    _Vue.prototype.$auth = auth
  }
}
