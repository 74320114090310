import Vue from 'vue'
import Vuetify from 'vuetify/lib/index.js'
import ru from 'vuetify/lib/locale/ru.js'
import es from 'vuetify/lib/locale/es.js'
import de from 'vuetify/lib/locale/de.js'
import icons from '@clickadilla/components/icons/index.js'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: {
      ru,
      es,
      de
    },
    current: 'en'
  },
  icons: {
    iconfont: 'mdi',
    values: icons
  },
  theme: {
    dark: false,
    themes: {
      light: { primary: '#605f5f' }
    },
    options: {
      customProperties: true,
      variations: false
    }
  }
})
