export default class DirectLinkIcon {
  id = null
  name = ''
  src = ''

  constructor(data) {
    if (!data) return

    this.id = data.id ?? null
    this.name = data.name ?? ''
    this.src = data.src ?? ''
  }
}
