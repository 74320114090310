<template>
  <div :class="{ 'pl-4': isMobile }">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'AdaptiveStringTable',
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      }
    }
  }
</script>
