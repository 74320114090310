export default class ProviderPlan {
  allowDisablingMainProvider = false
  allowPrivateSspFormats = false
  billingPeriodDays = null
  id = null
  maxProviders = null
  maxRequestsPerSecond = null
  price = null
  textColor = ''
  translations = []
  status = ''

  constructor(data) {
    if (!data) return

    this.allowDisablingMainProvider = data.allow_disabling_main_provider ?? false
    this.allowPrivateSspFormats = data.allow_private_ssp_formats ?? false
    this.billingPeriodDays = data.billing_period_days ?? null
    this.id = data.id ?? null
    this.maxProviders = data.max_providers ?? null
    this.maxRequestsPerSecond = data.max_requests_per_second ?? null
    this.price = data.price ?? null
    this.textColor = data.text_color ?? ''
    this.translations = data.translations ? this.#createTranslations(data.translations) : []
    this.status = data.status ?? ''
  }

  get transformMaxRequestsPerSecond() {
    return this.maxRequestsPerSecond ? `<${this.maxRequestsPerSecond}` : ''
  }

  pickCurrentLocale(localeValue) {
    if (!this.translations.length) return {}

    const currentTranslation = this.translations.find((translation) => translation.locale === localeValue)
    return currentTranslation
        || this.translations.find((translate) => translate.locale === 'en')
        || this.translations[0]
  }

  #createTranslations(translations) {
    return translations.map((translation) => ({
      name: translation.name ?? '',
      description: translation.description ?? '',
      locale: translation.locale ?? ''
    }))
  }
}
