<template>
  <v-list-item class="justify-center" @click="setIsMiniSidebarShown(!isMiniSidebarShown)">
    <v-list-item-action>
      <v-icon :class="[isMiniSidebarShown ? 'secondary--text' : 'primary--text']" size="24">
        {{ arrowSidebar }}
      </v-icon>
    </v-list-item-action>
    <v-list-item-title :class="[{ 'primary--text': !isMiniSidebarShown }]">
      {{ $t('main.collapse_panel') }}
    </v-list-item-title>
  </v-list-item>
</template>

<script>
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'ResizerButton',
    computed: {
      ...mapState('settings', ['isMiniSidebarShown']),
      arrowSidebar() {
        return this.isMiniSidebarShown ? '$arrow-right' : '$arrow-left'
      }
    },
    methods: {
      ...mapActions('settings', ['setIsMiniSidebarShown'])
    }
  }
</script>
