<template>
  <v-navigation-drawer
    :value="isSidebarShown"
    :mini-variant="isMiniSidebarShown"
    mini-variant-width="82"
    :permanent="$vuetify.breakpoint.lgAndUp"
    class="black sidebar-custom-scroll"
    mobile-breakpoint="1264"
    disable-resize-watcher
    touchless
    dark
    app
    @input="setIsSidebarShown($event)"
  >
    <menu-bar />
    <install-pwa v-if="pwaInstallIsShown" />
    <support-links class="mt-2" />
    <language-select
      v-if="getLocales.length"
      :class="[isMiniSidebarShown ? 'mx-1' : 'mx-4']"
    />
    <personal-manager v-if="accountManagerIsEnabled" />
    <template #append>
      <v-divider />
      <resizer-button />
    </template>
  </v-navigation-drawer>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import MenuBar from '@/components/main/Sidebar/MenuBar.vue'
  import SupportLinks from '@/components/main/Sidebar/SupportLinks.vue'
  import PersonalManager from '@/components/main/Sidebar/PersonalManager.vue'
  import ResizerButton from '@/components/main/Sidebar/ResizerButton.vue'
  import LanguageSelect from '@/components/main/Sidebar/LanguageSelect.vue'
  import InstallPwa from '@/components/main/Sidebar/installPwa.vue'

  export default {
    name: 'Sidebar',
    components: {
      LanguageSelect,
      MenuBar,
      SupportLinks,
      PersonalManager,
      ResizerButton,
      InstallPwa
    },
    computed: {
      ...mapState('settings', [
        'isMiniSidebarShown',
        'isSidebarShown',
        'pwaIsStarted',
        'adNetwork'
      ]),
      ...mapGetters('settings', ['getLocales']),
      pwaInstallIsShown() {
        return this.pwaIsStarted && this.$pwa.hasPrompt
      },
      accountManagerIsEnabled() {
        return this.adNetwork.settings.liveChat.type === 'intercom'
      }
    },
    methods: {
      ...mapActions('settings', ['setIsSidebarShown'])
    }
  }
</script>

<style lang="scss" scoped>
  .sidebar-custom-scroll {
    height: 100% !important;
  }
  .sidebar-custom-scroll ::-webkit-scrollbar {
    width: 5px;
  }

  ::v-deep.sidebar-custom-scroll {
    .v-navigation-drawer__content {
      scrollbar-gutter: stable both-edges;
    }
  }
</style>
