export default class RejectCause {
  details = ''
  id = null
  isSystem = false
  name = ''

  constructor(data) {
    if (!data) return

    this.details = data.details ?? ''
    this.id = data.id ?? null
    this.isSystem = data.is_system ?? false
    this.name = data.name ?? ''
  }
}
