const state = {
  dashboardIsLoading: false,
  chartIsLoading: false,
  tableIsLoading: false,
  balance: '-',
  reservedBalance: '-',
  typesChartStatistics: {
    columnType: 'cost',
    lineType: 'impressions'
  },
  cost: {
    todayCost: '0.00',
    yesterdayCost: '0.00',
    currentMonthCost: '0.00',
    previousMonthCost: '0.00'
  },
  campaigns: {
    pendingCampaignsCount: 0,
    activeCampaignsCount: 0,
    campaignsTotal: 0,
    pausedCampaignCount: 0
  },
  dateRange: {
    start: '',
    end: ''
  },
  tableStatistics: [],
  chartStatistics: []
}

export default state
