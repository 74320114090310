<template>
  <v-app-bar
    app
    :dense="!breakpoint.xsOnly"
    color="white"
    :class="['d-flex justify-space-between', { 'full-width': breakpoint.xsOnly }]"
  >
    <v-toolbar-title class="d-flex d-lg-none">
      <v-app-bar-nav-icon class="mr-1" light @click.stop="sidebarHandler()">
        <v-icon size="25" color="primary">
          $burger-menu
        </v-icon>
      </v-app-bar-nav-icon>
    </v-toolbar-title>

    <div class="d-flex align-center full-width">
      <server-time class="d-none d-lg-flex" />
      <div class="d-flex align-center">
        <div
          v-if="isBeta && fullManageIsApproved"
          class="d-flex align-center text-capitalize px-1 px-sm-0 px-md-1 mr-lg-3 ml-sm-0"
        >
          <v-icon size="18" color="primary">
            $check
          </v-icon>
          <span v-if="!isMobile" class="d-flex d-md-flex font-weight-bold text-sm-caption text-md-subtitle-2 text-truncate primary--text ml-1">{{ $t('main.full_manage.full_managed_title') }}</span>
        </div>

        <v-btn
          v-if="isImpersonating"
          small
          depressed
          outlined
          color="error"
          class="impersonate px-1 px-sm-0 px-md-1 mr-lg-3 ml-4 ml-sm-0 mr-sm-4"
        >
          <v-icon size="18">
            $warning
          </v-icon>
          <span v-if="!isMobile" class="d-flex d-md-flex ml-1">impersonate</span>
        </v-btn>
      </div>
      <div
        :class="[
          'd-flex align-center justify-space-between ml-0 ml-lg-auto',
          { 'full-width': breakpoint.mdAndDown }
        ]"
      >
        <div class="d-none d-sm-flex align-center ml-sm-auto mx-md-auto ml-lg-15">
          <spent-this-week
            v-if="adNetwork.settings.spentThisWeek"
            class="d-none d-md-flex align-center order-md-first order-lg-0 mr-md-8 mr-lg-16 pr-2"
          />
          <c-btn
            v-if="adFundsIsShown"
            depressed
            small
            color="primary"
            class="text-button mr-1 mr-md-4"
            :to="{ name: 'AddFunds' }"
            :label="$t('main.sidebar.add_funds').toUpperCase()"
            :icon-props="{
              icon: '$plus',
              size: 12
            }"
          />
          <user-balance />
        </div>

        <v-spacer class="d-flex d-sm-none" />

        <div class="d-flex align-center ml-3 ml-lg-15">
          <router-link
            v-if="adNetworkHasSectionNotifications"
            class="px-3 py-6"
            :to="{ name: 'Notification' }"
          >
            <v-icon :color="isShowNotificationBell ? 'primary' : 'secondary-darken'" size="18">
              $bells
            </v-icon>
          </router-link>
          <dropdown-menu class="ml-3 ml-md-0" />
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { datatableFormater } from '@clickadilla/datatable-formatter'
  import notificationsRepository from '@/services/repositories/notifications-repository.js'
  import SpentThisWeek from '@/components/main/SpentThisWeek.vue'
  import ServerTime from '@/components/main/ServerTime.vue'
  import UserBalance from '@/components/main/UserBalance.vue'
  import DropdownMenu from '@/components/main/DropdownMenu.vue'
  import guardSections from '@/types/guard-sections.js'
  import handleErrors from '@/services/handleErrors.js'

  export default {
    name: 'Toolbar',
    components: {
      SpentThisWeek,
      ServerTime,
      UserBalance,
      DropdownMenu,
      CBtn
    },
    data() {
      return {
        options: {
          page: 1,
          itemsPerPage: 0
        }
      }
    },
    computed: {
      ...mapState('settings', ['isSidebarShown', 'isMiniSidebarShown', 'adNetwork']),
      ...mapState('notification', ['unreadNotifications']),
      ...mapState('personal', ['fullManageStatus']),
      isShowNotificationBell() {
        return !!this.unreadNotifications
      },
      breakpoint() {
        return this.$vuetify.breakpoint
      },
      isImpersonating() {
        return !!this.$auth.user?.is_impersonating
      },
      isBeta() {
        return window.localStorage.getItem('isBeta')
      },
      fullManageIsApproved() {
        return this.fullManageStatus === 'approved'
      },
      adFundsIsShown() {
        let isShown = this.breakpoint.mdAndUp
        if (!(this.adNetworkHasSectionAddFunds && this.isImpersonating)) {
          isShown = this.adNetworkHasSectionAddFunds
        }
        return isShown
      },
      adNetworkHasSectionAddFunds() {
        return this.adNetwork.guardSections.some(({ name }) => name === guardSections.ADD_FUNDS)
      },
      adNetworkHasSectionNotifications() {
        return this.adNetwork.guardSections.some(({ name }) => name === guardSections.NOTIFICATIONS)
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      }
    },
    created() {
      if (this.adNetworkHasSectionNotifications) {
        this.fetchNotifications()
      }
    },
    methods: {
      ...mapActions('settings', ['setIsSidebarShown', 'setIsMiniSidebarShown']),
      ...mapActions('notification', ['setUnreadNotifications']),
      sidebarHandler() {
        if (this.breakpoint.lgAndUp) {
          return this.setIsMiniSidebarShown(!this.isMiniSidebarShown)
        }
        return this.setIsSidebarShown(!this.isSidebarShown)
      },
      async fetchNotifications() {
        try {
          const params = datatableFormater({
            options: this.options
          })
          const repository = await notificationsRepository.fetchNotifications(params)
          this.setUnreadNotifications(repository.unread_notifications)
        } catch (error) {
          handleErrors(error)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
    box-shadow: 0 0 5px rgba(33, 33, 33, 0.19);
  }
  .full-width {
    width: 100%;
  }
  ::v-deep .v-toolbar__content {
    width: 100%;
  }
</style>
