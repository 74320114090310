<template>
  <div>
    <span class="d-inline-block secondary-darken--text mr-2">{{ $t('main.spent_this_week') }}</span>

    <v-skeleton-loader :loading="spentIsLoading" type="chip">
      <router-link tag="div" :to="redirectLink" class="global-cursor-pointer">
        <span class="black--text">${{ toFixedByDefault(spentThisWeek) }}</span>
      </router-link>
    </v-skeleton-loader>
  </div>
</template>

<script>
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import { mapState } from 'vuex'
  import { showErrorNotification } from '@/plugins/global-methods.js'
  import { spentThisWeekRepository } from '@/services/repository-factory.js'
  import guardSections from '@/types/guard-sections.js'

  export default {
    name: 'SpentThisWeek',
    data() {
      return {
        spentIsLoading: false,
        spentThisWeek: 0
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork']),
      adNetworkHasSectionFinances() {
        return this.adNetwork.guardSections.some(({ name }) => name === guardSections.FINANCES)
      },
      redirectLink() {
        return this.adNetworkHasSectionFinances ? { name: 'Finances' } : ''
      }
    },
    created() {
      this.fetchSpentThisWeek()
    },
    methods: {
      async fetchSpentThisWeek() {
        this.spentIsLoading = true
        try {
          const statistics = await spentThisWeekRepository.show()
          const sumSpent = statistics.reduce((acc, current) => acc + Number(current.money), 0)
          this.spentThisWeek = toFixedByDefault(sumSpent, 4)
        } catch (error) {
          showErrorNotification(error.message)
        }
        this.spentIsLoading = false
      },
      toFixedByDefault
    }
  }
</script>
