import http from '../http.js'

export default {
  async list(params) {
    this.list.abort?.()
    const { data } = await http.get('/api/private/v1/feeds/statistics', {
      params,
      cancelToken: new http.CancelToken((abort) => {
        this.list.abort = abort
      })
    })
    return data.data
  }
}
