<template>
  <v-divider
    v-show="!isMiniSidebarShown"
    :class="['mb-1', { 'custom-divider': !isMiniSidebarShown }]"
  />
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'Divider',
    computed: {
      ...mapState('settings', ['isMiniSidebarShown'])
    }
  }
</script>

<style lang="scss" scoped>
  .custom-divider {
    margin-left: 30px;
    margin-right: 30px;
  }
</style>
