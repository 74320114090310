import RejectCause from './RejectCause.js'

export default class Approval {
  details = ''
  id = null
  rejectCause = new RejectCause()
  status = ''

  constructor(data) {
    if (!data) return

    this.details = data.details ?? ''
    this.id = data.id ?? null
    this.rejectCause = new RejectCause(data.reject_cause)
    this.status = data.status ?? ''
  }
}
