export default function calculateTotalStatistics(statistics, calcStrategy) {
  const impressionsCount = Object.values(statistics).reduce((acc, statisticItem) => {
    if (+statisticItem.impressions) {
      acc += 1
    }
    return acc
  }, 0)

  return statistics.reduce((acc, item, idx) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(item)) {
      if (!acc[key]) {
        acc[key] = null
      }
      if (calcStrategy[key]) {
        if (calcStrategy[key] === 'amount') {
          acc[key] += parseFloat(value)
        }
        if (calcStrategy[key] === 'average') {
          if (value) {
            acc[key] += parseFloat(value)
          }
          if (idx === statistics.length - 1 && impressionsCount) {
            acc[key] /= impressionsCount
          }
        }
        if (idx === statistics.length - 1) {
          if (typeof acc[key] === 'number' && !Number.isInteger(acc[key])) {
            acc[key] = key === 'cost'
              ? acc[key].toFixed(4)
              : acc[key].toFixed(2)
          }
        }
      }
    }
    return acc
  }, {})
}
