import http from '../http.js'
import urlFormatter from '../url-formatter.js'
import ValidationError from '@/services/classes/validation-error.js'
import CodeRequiredLoginError from '@/services/classes/CodeRequiredLoginError.js'
import SubscriptionPlan from '@/services/classes/SubscriptionPlan.js'

const getURL = urlFormatter({
  profile: '/api/private/v1/profile',
  sendForgetPasswordEmail: '/api/private/v1/auth/password/email',
  setNewPassword: '/api/private/v1/auth/password/reset',
  leaveImpersonate: '/api/private/v1/impersonate/leave',
  unsubscribe: '/api/private/v1/unsubscribe/{email}',
  confirmEmail: '/api/private/v1/register/{userId}/confirm/{code}'
})

export default {
  refresh: () => http.get('api/private/v1/auth/refresh'),
  profile2: async () => {
    const { data } = await http.get('api/private/v1/profile')
    return data.data
  },
  login: async (params) => {
    const response = await http.post('api/private/v1/auth/login', params)
    if (response.data && ['email', 'google'].includes(response.data.authentication_method)) {
      throw new CodeRequiredLoginError({
        authenticationMethod: response.data.authentication_method,
        codeExpirationTime: response.data.code_expiration_time,
        codeSentAt: response.data.application_time
      })
    }
    return response
  },
  register: async (requestBody) => {
    const { data } = await http.post('/api/private/v1/auth/register', requestBody)
    return data
  },
  async sendForgetPasswordEmail({ email, recaptcha }) {
    try {
      const endpoint = getURL('sendForgetPasswordEmail')
      const data = await http.post(endpoint, {
        email,
        'g-recaptcha-response': recaptcha
      })
      return data.data.data.message
    } catch (error) {
      const { errors } = error.response.data
      throw new ValidationError({
        email: errors.email || [],
        recaptcha: errors['g-recaptcha-response']
      })
    }
  },
  setNewPassword({
    email, password, passwordConfirm, token
  }) {
    const endpoint = getURL('setNewPassword')
    return http.post(endpoint, {
      email,
      password,
      password_confirmation: passwordConfirm,
      token
    })
  },
  profile() {
    const endpoint = getURL('profile')
    return http.get(endpoint).then((response) => {
      const { data } = response.data

      return {
        id: data.id,
        name: data.name,
        email: data.email,
        actions: {
          fullManageRequestable: data.actions.full_manage_requestable ?? false
        },
        fullManageStatus: data.full_manage_status,
        trafficProviderSubscription: new SubscriptionPlan(data.traffic_provider_subscription),
        phone: data.phone,
        balance: data.balance,
        accountType: data.account_type,
        accountManager: data.account_manager,
        companyName: data.company_name,
        companyAddress: data.company_address,
        companySite: data.company_site,
        vat: data.vat,
        additionalInfo: data.additional_info,
        adActionsNotifications: data.notifications_about_ad_actions,
        campaignActionsNotifications: data.notifications_about_campaign_actions,
        campaignTooltipsNotifications: data.notifications_about_campaign_tooltips,
        referralProgramPercent: data.referral_program_percent,
        isCompanyVerified: data.is_company_verified,
        verificationStatuses: data.verification_statuses,
        twoFactorAuthenticationMethod: data.two_factor_authentication_method,
        recurringPaymentsCustomer: data.recurring_payments_customer && {
          changeAmount: data.recurring_payments_customer.change_amount,
          customerData: {
            customerId: data.recurring_payments_customer.customer_data.customer_id
          },
          id: data.recurring_payments_customer.id,
          paymentMethodId: data.recurring_payments_customer.payment_method_id
        },
        messengers: data.messengers
      }
    })
  },
  requestFullManage: async () => http.post('/api/private/v1/profile/full-manage'),
  update: async (params) => {
    const { data } = await http.put('/api/private/v1/profile/update', params)
    return data
  },
  leaveImpersonate() {
    const endpoint = getURL('leaveImpersonate')
    return http.get(endpoint).then((res) => res.data.data.redirect_url)
  },
  async actualSubscribeData(email, signature) {
    const endpoint = getURL('unsubscribe', { email })
    const response = await http.get(endpoint, {
      params: {
        signature
      }
    })
    return {
      notificationsAboutAdActions: response.data.data.notifications_about_ad_actions,
      notificationsAboutCampaignActions: response.data.data.notifications_about_campaign_actions
    }
  },
  unsubscribe({
    email,
    notificationsAboutAdActions,
    notificationsAboutCampaignActions,
    signature
  }) {
    const request = {
      notifications_about_ad_actions: notificationsAboutAdActions,
      notifications_about_campaign_actions: notificationsAboutCampaignActions
    }

    return http
      .post(`/api/private/v1/unsubscribe/${email}/?signature=${signature}`, request)
      .then((response) => ({
        email,
        notificationsAboutAdActions: response.data.notifications_about_ad_actions,
        notificationsAboutCampaignActions: response.data.notifications_about_campaign_actions
      }))
  },
  confirmEmail({ userId, code }) {
    return http.post(`/api/private/v1/auth/register/${userId}/confirm/${code}`)
  },
  sendEmailConfirmation() {
    return http.post('/api/private/v1/profile/confirmations/email')
  },
  setLocale: async (locale) => {
    await http.put('api/private/v1/profile/locale', { locale })
  },
  google2faValidate: async (params) => {
    const { data } = await http.post('api/private/v1/profile/google2fa/validate', params)
    return data
  },
  google2fa: async () => {
    const { data } = await http.get('api/private/v1/profile/google2fa')
    return data.data
  },
  generateUrlTelegram: async () => {
    const { data } = await http.post('/api/private/v1/profile/telegram/generate-url')
    return data.data
  },
  unsubscribeTelegram: async () => {
    const { data } = await http.delete('api/private/v1/profile/telegram')
    return data.data
  },
  getURL
}
