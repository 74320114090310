import objectAssignByDictionary from '@/services/utils/object-assign-by-dictionary.js'
import Media from '@/services/classes/Media.js'

const dictionary = {
  id: 'id',
  domain: 'domain',
  is_new: 'isNew',
  premium: 'premium',
  tubercorp: 'tubercorp',
  site_report: 'siteReport',
  category_group: 'categoryGroup'
}

/**
 * @deprecated Use class {@link SiteNew}
 * @see SiteNew
 */
export default class Site {
  constructor(data) {
    this.id = null
    this.domain = ''
    this.isNew = false
    this.premium = false
    this.tubercorp = false
    this.siteReport = {}
    this.categoryGroup = {}
    this.logo = new Media()

    if (data) {
      objectAssignByDictionary(this, data, dictionary)
      this.categoryGroup = data.category_group ?? {}
      this.logo = new Media(data.logo)
      this.siteReport = {
        description: (data.site_report && data.site_report.description) || '',
        faviconUrl: (data.site_report && data.site_report.favicon_url) || '',
        similarwebAudienceAge: data.site_report?.similarweb_audience_age ?? '',
        similarwebCategory: data.site_report?.similarweb_category ?? '',
        similarwebCategoryRank: data.site_report?.similarweb_category_rank ?? '',
        similarwebFemale: data.site_report?.similarweb_female ?? '',
        similarwebGlobalRank: (data.site_report && data.site_report.similarweb_global_rank) || '-',
        similarwebMale: data.site_report?.similarweb_male ?? '',
        similarwebTimeOnSite: (data.site_report && data.site_report.similarweb_time_on_site) || '-',
        similarwebPagePerVisit:
          (data.site_report && data.site_report.similarweb_page_per_visit) || '-',
        similarwebTopCountries: data.site_report?.similarweb_top_countries ?? [],
        similarwebTotalVisits:
          (data.site_report && data.site_report.similarweb_total_visits) || '-',
        title: (data.site_report && data.site_report.title) || data.domain
      }
    }
  }
}
