export default class Country {
  id = null
  name = ''
  isoCode2 = ''
  isoCode3 = ''

  constructor(data) {
    if (!data) return

    this.id = data.id ?? null
    this.name = data.name ?? ''
    this.isoCode2 = data.iso_code_2 ?? ''
    this.isoCode3 = data.iso_code_3 ?? ''
  }
}
