import types from '@/store/personal/common/types.js'

const mutations = {
  [types.SET_ID]: (state, id) => {
    state.id = id
  },
  [types.SET_NAME]: (state, name) => {
    state.name = name
  },
  [types.SET_PHONE]: (state, phone) => {
    state.phone = phone
  },
  [types.SET_EMAIL]: (state, email) => {
    state.email = email
  },
  [types.SET_BALANCE]: (state, balance) => {
    state.balance = balance
  },
  [types.SET_ACCOUNT_TYPE]: (state, accountType) => {
    state.accountType = accountType
  },
  [types.SET_ACCOUNT_MANAGER]: (state, accountManager) => {
    state.accountManager = accountManager
  },
  [types.SET_COMPANY_NAME]: (state, companyName) => {
    state.companyName = companyName
  },
  [types.SET_COMPANY_ADDRESS]: (state, companyAddress) => {
    state.companyAddress = companyAddress
  },
  [types.SET_COMPANY_SITE]: (state, companySite) => {
    state.companySite = companySite
  },
  [types.SET_VAT]: (state, vat) => {
    state.vat = vat
  },
  [types.SET_ADDITIONAL_INFO]: (state, additionalInfo) => {
    state.additionalInfo = additionalInfo
  },
  [types.SET_AD_ACTIONS_NOTIFICATIONS]: (state, adActionsNotifications) => {
    state.adActionsNotifications = adActionsNotifications
  },
  [types.SET_CAMPAIGN_ACTIONS_NOTIFICATIONS]: (state, campaignActionsNotifications) => {
    state.campaignActionsNotifications = campaignActionsNotifications
  },
  [types.SET_CAMPAIGN_TOOLTIPS_NOTIFICATIONS]: (state, campaignTooltipsNotifications) => {
    state.campaignTooltipsNotifications = campaignTooltipsNotifications
  },
  [types.SET_TRAFFIC_PROVIDER_SUBSCRIPTION]: (state, trafficProviderSubscription) => {
    state.trafficProviderSubscription = trafficProviderSubscription
  },
  [types.SET_REFERRAL_PROGRAM_PERCENT]: (state, referralProgramPercent) => {
    state.referralProgramPercent = referralProgramPercent
  },
  [types.SET_IS_COMPANY_VERIFIED]: (state, isCompanyVerified) => {
    state.isCompanyVerified = isCompanyVerified
  },
  [types.SET_VERIFICATION_STATUSES]: (state, verificationStatuses) => {
    state.verificationStatuses = verificationStatuses
  },
  [types.SET_TWO_FACTOR_AUTHENTICATION_METHOD]: (state, twoFactorAuthenticationMethod) => {
    state.twoFactorAuthenticationMethod = twoFactorAuthenticationMethod
  },
  [types.SET_FETCH_IS_LOADING]: (state, fetchIsLoading) => {
    state.fetchIsLoading = fetchIsLoading
  },
  [types.SET_SAVE_IS_LOADING]: (state, saveIsLoading) => {
    state.saveIsLoading = saveIsLoading
  },
  [types.SET_ERRORS]: (state, errors) => {
    state.errors = errors
  },
  [types.SET_RECURRING_PAYMENTS_CUSTOMER]: (state, recurringPaymentsCustomer) => {
    state.recurringPaymentsCustomer = recurringPaymentsCustomer
  },
  [types.SET_USER_MESSENGERS]: (state, messengers) => {
    state.userMessengers = messengers
  },
  [types.REMOVE_USER_MESSENGER]: (state, removingMsgIndex) => {
    state.userMessengers.splice(removingMsgIndex, 1)
  },
  [types.SET_SUBSCRIBE_TELEGRAM]: (state, subscribeTelegram) => {
    state.subscribeTelegram = subscribeTelegram
  },
  [types.SET_FULL_MANAGE_STATUS]: (state, fullManageStatus) => {
    state.fullManageStatus = fullManageStatus
  },
  [types.SET_ACTIONS]: (state, actions) => {
    state.actions = actions
  }
}

export default mutations
