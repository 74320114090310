import { notificationEventTypes } from '@/views/Notification/NotificationBell/notificationt-types.js'
import { notificationsRepository } from '@/services/repository-factory.js'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    unreadNotifications: 0,
    recordsFiltered: 0,
    notifications: [],
    statuses: [
      {
        value: notificationEventTypes.CAMPAIGN_PAUSED_ACTIVATED,
        label: 'Campaign paused activated',
        color: 'primary'
      },
      {
        value: notificationEventTypes.CAMPAIGN_ACTIVATED,
        label: 'notification_bell.activated',
        color: 'primary'
      },
      {
        value: notificationEventTypes.CAMPAIGN_APPROVED,
        label: 'main.approval_statuses.approved',
        color: 'primary'
      },
      {
        value: notificationEventTypes.CAMPAIGN_REJECTED,
        label: 'main.approval_statuses.rejected',
        color: 'error white--text'
      },
      {
        value: notificationEventTypes.CAMPAIGN_LIMITS_BELOW_MIN,
        label: 'notification_bell.limits_below_minimal',
        color: 'warning white--text'
      },
      {
        value: notificationEventTypes.CAMPAIGN_FIRST_NEVER_PAID,
        label: 'notification_bell.first_never_paid',
        color: 'warning white--text'
      },
      {
        value: notificationEventTypes.CAMPAIGN_UNPROFITABLE_PAUSED,
        label: 'notification_bell.unprofitable_paused',
        color: 'warning white--text'
      },
      {
        value: notificationEventTypes.CAMPAIGN_LOW_PRICE,
        label: 'notification_bell.campaign_low_price',
        color: 'warning white--text'
      },
      {
        value: notificationEventTypes.AD_APPROVED,
        label: 'main.approval_statuses.approved',
        color: 'primary'
      },
      {
        value: notificationEventTypes.AD_REJECTED,
        label: 'main.approval_statuses.rejected',
        color: 'error white--text'
      },
      {
        value: notificationEventTypes.APPROVABLE_NEEDS_EDITS,
        label: 'main.approval_statuses.needs_edits',
        color: 'info'
      }
    ]
  },
  getters: {
    approvalStatusTypes: (state) => state.statuses
  },
  mutations: {
    SET_LOADING: (state, isLoading) => {
      state.isLoading = isLoading
    },
    SET_UNREAD_NOTIFICATIONS: (state, unreadNotifications) => {
      state.unreadNotifications = unreadNotifications
    },
    SET_NOTIFICATIONS: (state, notifications) => {
      state.notifications = notifications
    },
    SET_RECORDS_FILTERED: (state, recordsFiltered) => {
      state.recordsFiltered = recordsFiltered
    }
  },
  actions: {
    setUnreadNotifications({ commit }, unreadNotifications) {
      commit('SET_UNREAD_NOTIFICATIONS', unreadNotifications)
    },
    setNotifications({ commit }, notifications) {
      commit('SET_NOTIFICATIONS', notifications)
    },
    async getNotifications({ commit }, params) {
      commit('SET_LOADING', true)
      const repository = await notificationsRepository.fetchNotifications(params)
      commit('SET_NOTIFICATIONS', repository.data)
      commit('SET_RECORDS_FILTERED', repository.recordsFiltered)
      commit('SET_UNREAD_NOTIFICATIONS', repository.unread_notifications)

      commit('SET_LOADING', false)
    },
    async read({ commit, state, dispatch }, { notificationId, params }) {
      const response = await notificationsRepository.readNotifications(notificationId)

      const notifications = state.notifications.filter(
        (notification) => notification.id !== notificationId
      )
      commit('SET_NOTIFICATIONS', notifications)
      commit('SET_UNREAD_NOTIFICATIONS', response.unread_notifications)

      if (state.notifications.length === 0 && state.unreadNotifications !== 0) {
        dispatch('getNotifications', params)
      }
    },
    async readAll({ commit, dispatch }, params) {
      await notificationsRepository.readAllNotifications()
      commit('SET_NOTIFICATIONS', [])
      dispatch('getNotifications', params)
    },
    async remove({ commit }) {
      await notificationsRepository.removeNotifications()
      commit('SET_NOTIFICATIONS', [])
      commit('SET_UNREAD_NOTIFICATIONS', 0)
    }
  }
}
