import AdNetwork from '@/services/classes/AdNetwork.js'
import DirectLinkIcon from '@/services/classes/DirectLinkIcon.js'
import PlacementType from '@/services/classes/PlacementType.js'
import Country from '@/services/classes/Country.js'
import PaymentMethod from '@/services/classes/PaymentMethod.js'

export default class Application {
  clientAdFormats = []
  adNetwork = new AdNetwork()
  adTypes = this.#createAdTypes()
  campaignUpdatingRuleTypes = []
  campaignUpdatingRuleConditionFields = []
  defaultInPageSkinId = null
  defaultUniquesCap = null
  defaultUniquesTtl = null
  directLinkIcons = []
  flatDealAdFormatIds = []
  flatDealApplicationLimitTypes = []
  flatDealCountryGroups = []
  flatDealDefaultLimitType = ''
  flatDealDefaultValueKey = ''
  flatDealTiers = []
  flatDealTrafficTypes = []
  inStreamAdSkipTimeOptions = []
  limitTypesByPricingModel = {}
  mediaFileSizeLimits = {}
  minAddFundsAmount = null
  maxCampaignUpdatingRuleConditions = null
  notificationCategoryGroups = []
  notificationCategories = []
  paymentMethods = []
  placementTypes = []
  rotationTypes = []
  settings = this.#createSettings()
  smartBiddingByAdFormatTypeAndPricingModel = {}
  serverTime = ''
  trafficChartPricingModels = []
  trafficProviderMethodTypes = []
  trafficProviderPricingModels = []
  trafficProviderVerticalTypes = []
  twoFactorAuthenticationMethods = []

  constructor(data) {
    if (!data) return

    this.clientAdFormats = Array.isArray(data.client_ad_formats)
      ? data.client_ad_formats
      : []
    this.adNetwork = new AdNetwork(data.ad_network)
    this.adTypes = this.#createAdTypes(data.ad_types)
    this.campaignUpdatingRuleTypes = data.campaign_updating_rule_types ?? []
    this.campaignUpdatingRuleConditionFields = data.campaign_updating_rule_condition_fields ?? []
    this.defaultInPageSkinId = data.default_in_page_skin_id ?? null
    this.defaultUniquesCap = data.default_uniques_cap ?? null
    this.defaultUniquesTtl = data.default_uniques_ttl ?? null
    this.directLinkIcons = Array.isArray(data.direct_link_icons)
      ? data.direct_link_icons.map((icon) => new DirectLinkIcon(icon))
      : []
    this.flatDealAdFormatIds = data.flat_deal_ad_format_ids ?? []
    this.flatDealApplicationLimitTypes = data.flat_deal_application_limit_types ?? []
    this.flatDealCountryGroups = Array.isArray(data.flat_deal_country_groups)
      ? this.#createflatDealCountryGroups(data.flat_deal_country_groups)
      : []
    this.flatDealDefaultLimitType = data.flat_deal_default_limit_type ?? ''
    this.flatDealDefaultValueKey = data.flat_deal_default_value_key ?? ''
    this.flatDealTiers = data.flat_deal_tiers ?? []
    this.flatDealTrafficTypes = data.flat_deal_traffic_types ?? []
    this.inStreamAdSkipTimeOptions = data.in_stream_ad_skip_time_options ?? []
    this.limitTypesByPricingModel = data.limit_types_by_pricing_model ?? {}
    this.mediaFileSizeLimits = data.media_file_size_limits ?? {}
    this.minAddFundsAmount = data.min_add_funds_amount ?? null
    this.maxCampaignUpdatingRuleConditions = data.max_campaign_updating_rule_conditions ?? null
    this.notificationCategories = data.notification_categories ?? []
    this.notificationCategoryGroups = data.notification_category_groups ?? []
    this.paymentMethods = Array.isArray(data.payment_methods)
      ? data.payment_methods.map((method) => new PaymentMethod(method))
      : []
    this.placementTypes = Array.isArray(data.placement_types)
      ? data.placement_types.map((placementType) => new PlacementType(placementType))
      : []
    this.rotationTypes = data.rotation_types ?? []
    this.settings = this.#createSettings(data.settings)
    this.smartBiddingByAdFormatTypeAndPricingModel = data.smart_bidding_by_ad_format_type_and_pricing_model ?? {}
    this.serverTime = data.time ?? ''
    this.trafficChartPricingModels = data.traffic_chart_pricing_models ?? []
    this.trafficProviderMethodTypes = data.traffic_provider_method_types ?? []
    this.trafficProviderPricingModels = data.traffic_provider_pricing_models ?? []
    this.trafficProviderVerticalTypes = data.traffic_provider_vertical_types ?? []
    this.twoFactorAuthenticationMethods = data.two_factor_authentication_methods ?? []
  }

  #createAdTypes(adTypes) {
    return {
      banner: adTypes?.banner ?? [],
      inStream: adTypes?.in_stream_ad ?? [],
      interstitial: adTypes?.interstitial ?? []
    }
  }

  #createSettings(settings) {
    return {
      cardIoLink: settings?.card_io_link ?? '',
      cardVerificationRequiredForPayments: Boolean(
        Number(settings?.card_verification_required_for_payments)
      ),
      companyCardIoLink: settings?.company_card_io_link ?? '',
      defaultRotationHoursThreshold: settings?.default_rotation_hours_threshold ?? null,
      maxWebPushSubscriptionDays: Number(settings?.max_web_push_subscription_days ?? ''),
      defaultRotationImpressionsThreshold: settings?.default_rotation_impressions_threshold ?? null,
      minLimitInMoney: Number(settings?.min_limit_in_money ?? ''),
      minLimitInImpressions: Number(settings?.min_limit_in_impressions ?? ''),
      minLimitInClicks: Number(settings?.min_limit_in_clicks ?? ''),
      undefinedSiteId: Number(settings?.undefined_site_id ?? ''),
      undefinedSpotTdsId: Number(settings?.undefined_spot_tds_id ?? '')
    }
  }

  #createflatDealCountryGroups(flatDealCountryGroups) {
    return flatDealCountryGroups.map((countryGroup) => ({
      adFormat: countryGroup.ad_format ?? '',
      countries: countryGroup.countries.map((country) => new Country(country)),
      id: countryGroup.id ?? null,
      name: countryGroup.name ?? ''
    }))
  }
}
