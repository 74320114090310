import Media from '@/services/classes/Media.js'
import Country from '@/services/classes/Country.js'

export default class SiteNew {
  categoryGroup = this.#createCategoryGroup({})
  domain = ''
  id = null
  isNew = false
  logo = new Media()
  premium = false
  siteReport = this.#createSiteReport({})
  tubecorp = false

  constructor(data) {
    if (!data) return

    this.categoryGroup = this.#createCategoryGroup(data.category_group || {})
    this.domain = data.domain ?? ''
    this.id = data.id ?? null
    this.isNew = data.is_new ?? false
    this.logo = new Media(data.logo)
    this.premium = data.premium ?? false
    this.siteReport = this.#createSiteReport(data.site_report || {})
    this.tubecorp = data.tubecorp ?? false
  }

  #createCategoryGroup(data) {
    return {
      id: data.id ?? null,
      name: data.name ?? ''
    }
  }

  #createSiteReport(data) {
    return {
      description: data.description ?? '',
      faviconUrl: data.favicon_url ?? '',
      similarwebAudienceAge: data.similarweb_audience_age ?? {}, // не используется
      similarwebCategory: data.similarweb_category ?? '',
      similarwebCategoryRank: data.similarweb_category_rank ?? '',
      similarwebFemale: data.similarweb_female ?? null,
      similarwebGlobalRank: data.similarweb_global_rank ?? null,
      similarwebMale: data.similarweb_male ?? null,
      similarwebPagePerVisit: data.similarweb_page_per_visit ?? null,
      similarWebPageViews: data.similarweb_page_views ?? null,
      similarwebPercentDesktopVisit: data.similarweb_percent_desktop_visit ?? null,
      similarwebPercentMobileWebVisit: data.similarweb_percent_mobile_web_visit ?? null,
      similarwebTimeOnSite: data.similarweb_time_on_site ?? null,
      similarwebTopCountries:
        data.similarweb_top_countries?.map?.((item) => ({
          value: item.value ?? null,
          country: new Country(item.country)
        })) ?? [],
      similarwebTotalVisits: data.similarweb_total_visits ?? null,
      similarwebTrafficSources: data.similarweb_traffic_sources?.map?.((item) => ({
        id: item.id,
        type: item.type,
        organicViews: item.organic_views,
        paidViews: item.paid_views
      })) ?? [],
      title: data.title ?? ''
    }
  }
}
