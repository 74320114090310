import http from '../http.js'
import urlFormatter from '../url-formatter.js'

const getURL = urlFormatter({
  list: '/api/private/v1/posts'
})

export default {
  list() {
    const endpoint = getURL('list')
    return http.get(endpoint).then((response) => response.data.data)
  },
  getURL
}
