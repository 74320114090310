export default {
  ADD_FUNDS: 'add funds',
  ADS: 'ads',
  API: 'api',
  CAMPAIGNS: 'campaigns',
  CAMPAIGN_STATISTICS: 'campaign statistics',
  DASHBOARD: 'dashboard',
  FINANCES: 'finances',
  NOTIFICATIONS: 'notifications',
  REFERRAL_PROGRAM: 'referral program',
  RTB: 'rtb',
  RTB_STATISTICS: 'rtb statistics',
  SSP_STATISTICS: 'ssp statistics',
  PROFILE: 'profile',
  SUCCESSFUL_REGISTRATION: 'successful registration',
  TRACKING: 'tracking',
  TRAFFIC_CHART: 'traffic chart',
  INVOICE_DOWNLOADS: 'invoice downloads',
  TRAFFIC_PROVIDERS: 'traffic providers',
  FLAT_DEALS: 'flat deals',
  CAMPAIGN_OFFERS: 'campaign offers'
}
