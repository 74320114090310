import Vue from 'vue'
import copy from 'copy-to-clipboard'

export default {
  install() {
    Vue.prototype.$copy = copy

    Vue.prototype.$showSuccessNotification = (message) => {
      Vue.prototype.$toast(message, {
        type: 'success'
      })
    }

    Vue.prototype.$showErrorNotification = (message) => {
      Vue.prototype.$toast(message, {
        type: 'error'
      })
    }
  }
}

export const showErrorNotification = (text) => Vue.prototype.$toast(text, {
  type: 'error',
  title: text
})
