import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import router from '@/router.js'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracingOptions: {
      trackComponents: true,
      hooks: ['activate', 'create', 'destroy', 'mount', 'update']
    },
    logErrors: true,
    attachProps: true,
    attachStacktrace: true
  })
}
