<template>
  <v-list
    :class="[
      'd-flex flex-wrap mx-4 px-0 py-0',
      isMiniSidebarShown ? 'flex-column align-center pa-0 mb-0' : 'mb-4'
    ]"
    flat
  >
    <v-tooltip v-for="(item, key) in supportMenu" :key="key" right color="primary">
      <template #activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <v-list-item
            :href="item.href"
            :class="[
              'd-flex pa-0 min-height-auto flex-unset',
              isMiniSidebarShown ? 'mb-5 mr-0' : 'mr-5 mt-2 mb-4'
            ]"
            v-bind="isTooltipShown(attrs)"
            v-on="isTooltipShown(on)"
            @click="item.liveChat ? liveChatHandler() : null"
          >
            <v-icon size="18" :class="{ 'primary--text': hover }">
              {{ item.icon }}
            </v-icon>
          </v-list-item>
        </v-hover>
      </template>
      <span>{{ item.text }}</span>
    </v-tooltip>
  </v-list>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'SupportLinks',
    computed: {
      ...mapState('settings', ['isMiniSidebarShown', 'adNetwork']),
      supportMenu() {
        return [
          {
            text: this.$t('main.help'),
            href: this.adNetwork.settings.helpUrls.help,
            icon: '$question-stroke',
            isShown: !!this.adNetwork.settings.helpUrls.help
          },
          {
            text: 'Skype',
            href: this.adNetwork.settings.socials.skype,
            icon: '$skype',
            isShown: !!this.adNetwork.settings.socials.skype
          },
          {
            text: 'Twitter',
            href: this.adNetwork.settings.socials.twitter,
            icon: '$x-twitter',
            isShown: !!this.adNetwork.settings.socials.twitter
          },
          {
            text: 'Facebook',
            href: this.adNetwork.settings.socials.facebook,
            icon: '$facebook-outlined',
            isShown: !!this.adNetwork.settings.socials.facebook
          },
          {
            text: 'Telegram',
            href: this.adNetwork.settings.socials.telegram,
            icon: '$telegram-outlined',
            isShown: !!this.adNetwork.settings.socials.telegram
          },
          {
            text: 'Youtube',
            href: this.adNetwork.settings.socials.youtube,
            icon: '$youtube',
            isShown: !!this.adNetwork.settings.socials.youtube
          },
          {
            text: 'Linkedin',
            href: this.adNetwork.settings.socials.linkedin,
            icon: '$linkedin-outlined',
            isShown: !!this.adNetwork.settings.socials.linkedin
          },
          {
            text: 'Instagram',
            href: this.adNetwork.settings.socials.instagram,
            icon: '$instagram',
            isShown: !!this.adNetwork.settings.socials.instagram
          },
          {
            text: 'vk',
            href: this.adNetwork.settings.socials.vk_group,
            icon: '$vk-outlined',
            isShown: !!this.adNetwork.settings.socials.vk_group
          },
          {
            text: 'E-mail',
            href: `mailto:${this.adNetwork.settings.socials.email}`,
            icon: '$mail',
            isShown: !!this.adNetwork.settings.socials.email
          },
          {
            text: this.$t('main.live_chat'),
            icon: '$message',
            liveChat: true,
            isShown: !!this.adNetwork.settings.liveChat.type
          }
        ].filter((item) => item.isShown)
      },
      isTooltipShown() {
        return (paramTooltip) => this.isMiniSidebarShown && paramTooltip
      }
    },
    methods: {
      liveChatHandler() {
        const { liveChat } = this.adNetwork.settings
        if (liveChat.type === 'intercom') {
          this.$intercom.show()
        } else if (liveChat.type === 'tawk') {
          this.$tawk.toggleChat()
        } else if (liveChat.type === 'dashly') {
          this.$dashly.openChat()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .min-height-auto {
    min-height: auto;
  }

  .flex-unset {
    flex: unset;
  }
</style>
