<template>
  <div v-if="widgetHtml">
    <!-- eslint-disable vue/no-v-html -->
    <div ref="banner" class="sidebar-banner-content pa-2 mx-5 my-3" v-html="widgetHtml" />
    <!-- eslint-enable -->
    <divider />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import Divider from '@/components/main/Sidebar/Divider.vue'

  export default {
    name: 'WidgetBanner',
    components: { Divider },
    computed: {
      ...mapState('settings', ['adNetwork']),
      widgetHtml() {
        const { sidebarWidget } = this.adNetwork.settings
        const sidebarWidgetByLocale = sidebarWidget[this.$i18n.locale]
        return sidebarWidgetByLocale || sidebarWidget.en
      }
    },
    watch: {
      async widgetHtml() {
        await this.$nextTick()
        if (this.$refs.banner) {
          this.$refs.banner.removeEventListener('click', this.bannerClickHandler)
          this.$refs.banner.addEventListener('click', this.bannerClickHandler)
          this.$on('hook:beforeDestroy', () => this.$refs.banner.removeEventListener('click', this.bannerClickHandler))
        }
      }
    },
    methods: {
      bannerClickHandler(event) {
        if (event.target.classList.contains('open-intercom')) {
          this.$intercom.show()
        }
      }
    }
  }
</script>

<style lang="scss">
  .sidebar-banner-content {
    position: relative;

    * {
      max-width: 100%;
    }
  }
</style>
