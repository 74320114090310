import http from '../http.js'
import urlFormatter from '../url-formatter.js'

const getURL = urlFormatter({
  find: '/api/private/v1/regions'
})

export default {
  find({ query = '', countriesIds = [], regionsIds = [] }) {
    const endpoint = getURL('find')
    return http
      .post(endpoint, {
        query,
        country_ids: countriesIds,
        region_ids: regionsIds
      })
      .then((response) => response.data.data)
  },
  getURL
}
