export default {
  SET_BALANCE: 'SET_BALANCE',
  SET_RESERVED_BALANCE: 'SET_RESERVED_BALANCE',
  SET_DATE_RANGE_START: 'SET_DATE_RANGE_START',
  SET_DATE_RANGE_END: 'SET_DATE_RANGE_END',
  SET_COST: 'SET_COST',
  SET_CAMPAIGN: 'SET_CAMPAIGN',
  SET_COLUMN_TYPE: 'SET_COLUMN_TYPE',
  SET_LINE_TYPE: 'SET_LINE_TYPE',
  SET_DASHBOARD_IS_LOADING: 'SET_DASHBOARD_IS_LOADING',
  SET_CHART_IS_LOADING: 'SET_CHART_IS_LOADING',
  SET_TABLE_IS_LOADING: 'SET_TABLE_IS_LOADING',
  SET_CHART_STATISTICS: 'SET_CHART_STATISTICS',
  SET_TABLE_STATISTICS: 'SET_TABLE_STATISTICS'
}
