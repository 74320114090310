const state = {
  id: [],
  name: [],
  email: '',
  balance: [],
  accountType: [],
  recurringPaymentsCustomer: {},
  accountManager: [],
  companyName: [],
  companyAddress: [],
  companySite: [],
  vat: [],
  additionalInfo: [],
  adActionsNotifications: [],
  campaignActionsNotifications: [],
  campaignTooltipsNotifications: false,
  trafficProviderSubscription: {},
  referralProgramPercent: [],
  isCompanyVerified: [],
  verificationStatuses: [],
  twoFactorAuthenticationMethod: [],
  userMessengers: [],
  messengersForSave: [],
  phone: null,
  fullManageStatus: null,
  actions: {},

  fetchIsLoading: false,
  saveIsLoading: false,
  errors: {},
  accountTypes: {
    personal: 'personal',
    company: 'company'
  },
  subscribeTelegram: null
}

export default state
