import types from '@/store/dashboard/common/types.js'

const mutations = {
  [types.SET_BALANCE]: (state, val) => {
    state.balance = val
  },
  [types.SET_RESERVED_BALANCE]: (state, val) => {
    state.reservedBalance = val
  },
  [types.SET_DATE_RANGE_START]: (state, val) => {
    state.dateRange.start = val
  },
  [types.SET_DATE_RANGE_END]: (state, val) => {
    state.dateRange.end = val
  },
  [types.SET_COST]: (state, val) => {
    state.cost = val
  },
  [types.SET_CAMPAIGN]: (state, val) => {
    state.campaigns = val
  },
  [types.SET_COLUMN_TYPE]: (state, val) => {
    state.typesChartStatistics.columnType = val
  },
  [types.SET_LINE_TYPE]: (state, val) => {
    state.typesChartStatistics.lineType = val
  },
  [types.SET_DASHBOARD_IS_LOADING]: (state, val) => {
    state.dashboardIsLoading = val
  },
  [types.SET_CHART_IS_LOADING]: (state, val) => {
    state.chartIsLoading = val
  },
  [types.SET_TABLE_IS_LOADING]: (state, val) => {
    state.tableIsLoading = val
  },
  [types.SET_CHART_STATISTICS]: (state, val) => {
    state.chartStatistics = val
  },
  [types.SET_TABLE_STATISTICS]: (state, val) => {
    state.tableStatistics = val
  }
}

export default mutations
