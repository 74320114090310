<template>
  <v-list v-if="manager" class="account-manager">
    <v-list-item
      :class="[
        'account-manager__switcher',
        {
          active: isActive,
          'ml-3': !isMiniSidebarShown
        }
      ]"
      @click="isActive = !isActive"
    >
      <v-list-item-action :class="{ 'mr-2': $i18n.locale !== 'en' && !isMiniSidebarShown }">
        <v-icon color="primary">
          mdi-account-tie
        </v-icon>
      </v-list-item-action>

      <v-list-item-title :class="['custom-title', { 'black--text': isActive }]">
        {{ $t('main.personal_manager') }}
      </v-list-item-title>
    </v-list-item>

    <v-expand-transition>
      <div v-if="isActive" class="pl-4 mt-3">
        <v-layout align-center>
          <v-avatar size="60px">
            <v-img :src="managerAvatarUrl" :lazy-src="defaultAvatar" alt="avatar">
              <template #placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="secondary-lighten" />
                </v-row>
              </template>
            </v-img>
          </v-avatar>

          <div class="account-manager__info ml-2">
            <div class="account-manager__name">
              {{ manager.name }}
            </div>

            <a class="text-caption" href="#" @click="$intercom.showNewMessage()">
              {{ $t('main.live_chat') }}
            </a>
          </div>
        </v-layout>
      </div>
    </v-expand-transition>
  </v-list>
</template>

<script>
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'PersonalManager',
    data() {
      return {
        isActive: false,
        defaultAvatar: require('@/assets/support.jpg')
      }
    },
    computed: {
      ...mapState('settings', ['isSidebarShown', 'isMiniSidebarShown']),
      manager() {
        return this.$auth.user?.account_manager
      },
      managerAvatarUrl() {
        if (!this.manager || !this.manager.avatar_url) {
          return this.defaultAvatar
        }

        return this.manager.avatar_url
      }
    },
    watch: {
      isActive(val) {
        if (val) {
          this.setIsMiniSidebarShown(false)
        }
      },
      isMiniSidebarShown(val) {
        if (val) {
          this.isActive = false
        }
      }
    },
    methods: {
      ...mapActions('settings', ['setIsMiniSidebarShown'])
    }
  }
</script>

<style lang="scss" scoped>
  .account-manager {
    &__switcher {
      border-radius: 5px 0 0 5px;
      &:before {
        border-radius: 5px 0 0 5px;
      }
    }

    &__name,
    &__email {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    &__name {
      font-size: 15px;
    }

    &__info {
      display: block;
      width: 100%;
      overflow: hidden;
    }
  }
  .custom-title {
    font-size: 14px !important;
  }
  .active {
    background-color: var(--v-backgroundColor-base);
    &:before {
      background-color: rgba(0, 0, 0, 0);
    }
    .menu-item__icon {
      color: var(--v-primary-base);
    }
    .v-list-item__title {
      color: var(--v-black-base) !important;
    }
  }

  .v-list-item {
    min-height: 34px !important;

    &__action,
    &__icon {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
    }
  }
</style>
