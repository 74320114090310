export default {
  install(_Vue) {
    const adServer = new _Vue({
      data() {
        return {
          inited: false,
          spotNumber: 0
        }
      },
      methods: {
        loadAd(id, dataZone) {
          window._ASO.loadAd(id, dataZone)
        },
        async init() {
          if (!this.inited) {
            await this.loadScript()
            this.inited = true
          }
          this.spotNumber += 1
          return `html-id-${this.spotNumber}`
        },
        loadScript() {
          return new Promise((resolve) => {
            const script = document.createElement('script')
            script.async = true
            script.setAttribute('src', 'https://media.aso1.net/js/code.min.js')
            script.setAttribute('data-cfasync', 'false')
            script.onload = resolve
            document.body.appendChild(script)
          })
        }
      }
    })

    _Vue.prototype.$adServer = adServer
  }
}
