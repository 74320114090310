import actions from './common/actions.js'
import mutations from './common/mutations.js'
import getters from './common/getters.js'
import state from './common/state.js'

const collectionsModule = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default collectionsModule
