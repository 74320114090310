<template>
  <v-app>
    <v-main class="fill-height align-center mx-auto">
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    name: 'DefaultLayout'
  }
</script>
