import http from '../http.js'

export default {
  async list(params) {
    this.list.abort?.()
    const { data } = await http.get('/api/private/v1/supply-side-platforms/statistics', {
      params: {
        start: params.dates.start,
        end: params.dates.end,
        supplySidePlatforms: params.supplySidePlatforms,
        groupBy: params.groupBy
      },
      cancelToken: new http.CancelToken((abort) => {
        this.list.abort = abort
      })
    })
    return data.data
  },
  async supplySidePlatforms() {
    const { data } = await http.get('api/private/v1/supply-side-platforms/all')
    return data.data
  }
}
