import types from '@/store/trafficChart/common/types.js'

const mutations = {
  [types.SET_TRAFFIC_CHART_IS_LOADING]: (state, value) => {
    state.trafficChartIsLoading = value
  },
  [types.SET_TRAFFIC]: (state, value) => {
    state.traffic = value
  },
  [types.SET_COUNTRIES_IDS]: (state, value) => {
    state.countriesIds = value
  },
  [types.SET_PRICING_MODEL]: (state, value) => {
    state.pricingModel = value
  },
  [types.SET_TRAFFIC_TYPE_ID]: (state, value) => {
    state.trafficTypeId = value
  },
  [types.SET_SELECT_ROTATION_AD_TYPE]: (state, value) => {
    state.selectRotationAdType = value
  },
  [types.SET_AD_FORMAT_ID]: (state, value) => {
    state.adFormatId = value
  },
  [types.SET_IS_PREMIUM_SITES]: (state, value) => {
    state.isPremiumSites = value
  },
  [types.SET_PREMIUM_SITES_BY_FORMAT]: (state, value) => {
    state.premiumSitesByFormat = value
  },
  [types.SET_PREMIUM_SITES_IDS]: (state, value) => {
    state.premiumSitesIds = value
  },
  [types.SET_OPERATING_SYSTEMS_IDS]: (state, value) => {
    state.operatingSystemsIds = value
  },
  [types.SET_COUNTRY_PRESET]: (state, value) => {
    state.countryPreset = value
  },
  [types.SET_PREMIUM_SITES_IS_LOADING]: (state, value) => {
    state.premiumSitesIsLoading = value
  }
}

export default mutations
