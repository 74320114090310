import Media from '@/services/classes/Media.js'

export default class InPageAdCreative {
  description = ''
  icon = new Media()
  id = null
  image = new Media()
  isActive = true
  title = ''
  url = ''
  priority = null
  inPageSkin = {
    id: null,
    tdsId: null,
    name: '',
    inPageSkinGroup: {
      id: null,
      name: ''
    }
  }

  constructor(data) {
    if (!data) return

    this.description = data.description ?? ''
    this.icon = new Media(data.icon)
    this.id = data.id ?? null
    this.image = new Media(data.image)
    this.isActive = data.is_active ?? true
    this.title = data.title ?? ''
    this.url = data.url ?? ''
    this.priority = data.priority ?? null
    this.inPageSkin = {
      id: data.in_page_skin?.id ?? null,
      tdsId: data.in_page_skin?.tds_id ?? null,
      name: data.in_page_skin?.name ?? '',
      inPageSkinGroup: {
        id: data.in_page_skin?.in_page_skin_group?.id ?? null,
        name: data.in_page_skin?.in_page_skin_group?.name ?? ''
      }
    }
  }
}
