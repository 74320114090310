export default class Rule {
  id = null
  action = ''
  periodDays = ''
  conditions = []
  isActive = false

  constructor(data) {
    if (!data) return

    this.id = data.id ?? null
    this.action = data.action ?? ''
    this.isActive = data.is_active ?? false
    this.periodDays = data.period_days ?? ''
    this.conditions = data.conditions?.map((condition) => ({
      field: condition.field ?? '',
      operation: condition.operation ?? '',
      value: condition.value ?? ''
    })) ?? []
  }

  addSubRule(data) {
    this.conditions.push(data)
  }

  updateCondition(index, data) {
    this.conditions.splice(index, 1, data)
  }

  deleteCondition(index) {
    this.conditions.splice(index, 1)
  }

  updateRuleProperty(propertyName, newValue) {
    if (propertyName in this) {
      this[propertyName] = newValue
    }
  }

  updateRule(data) {
    if (!data) return

    this.action = data.action
    this.isActive = data.isActive
    this.periodDays = data.periodDays
    this.conditions = data?.conditions?.map((condition) => ({
      field: condition.field ?? '',
      operation: condition.operation ?? '',
      value: condition.value ?? ''
    })) ?? []
  }

  static transformRequest(data) {
    return {
      action: data.action,
      is_active: data.isActive,
      period_days: data.periodDays,
      conditions: data.conditions.map((item) => ({
        ...item,
        value: `${item.value}`
      }))
    }
  }
}
