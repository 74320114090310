export default class PaymentMethod {
  bonuses = []
  feePercent = null
  id = null
  logo = ''
  minAddFundsAmount = null
  strategy = ''
  translations = []
  type = ''

  constructor(data) {
    if (!data) return

    this.bonuses = Array.isArray(data.bonuses) ? this.#createBonuses(data.bonuses) : []
    this.feePercent = data.fee_percent ?? null
    this.id = data.id ?? null
    this.logo = data.logo ?? ''
    this.minAddFundsAmount = data.min_add_funds_amount ?? null
    this.strategy = data.strategy ?? ''
    this.translations = Array.isArray(data.translations)
      ? this.#createTranslations(data.translations)
      : []
    this.type = data.type ?? ''
  }

  #createBonuses(bonuses) {
    return bonuses.map((bonus) => ({
      bonusPercent: bonus.bonus_percent ?? null,
      fromAmount: bonus.from_amount ?? null,
      id: bonus.id ?? null
    }))
  }
  #createTranslations(translations) {
    return translations.map((translation) => ({
      description: translation.description ?? '',
      id: translation.id ?? null,
      locale: translation.locale ?? '',
      name: translation.name ?? ''
    }))
  }
}
