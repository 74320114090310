import http from '../http.js'

export default {
  async table(params) {
    this.table.abort?.()
    const { data } = await http.get('/api/private/v1/flat-deals', {
      params,
      cancelToken: new http.CancelToken((abort) => {
        this.table.abort = abort
      })
    })
    return data
  },
  show: async (flatDealId) => {
    const { data } = await http.get(`/api/private/v1/flat-deals/${flatDealId}`)
    return data.data
  },
  price: async (flatDealId, params) => {
    const { data } = await http.post(`/api/private/v1/flat-deals/${flatDealId}/price`, params)
    return data.data
  },
  similarFlatDeals: async (flatDealId) => {
    const data = await http.get(`/api/private/v1/flat-deals/${flatDealId}/similar`)
    return data.data
  },
  store: async (params) => {
    const { data } = await http.post('/api/private/v1/flat-deal-applications', params)
    return data.data
  },
  document: async (applicationId) => {
    const { data } = await http.get(
      `/api/private/v1/flat-deal-applications/${applicationId}/document`,
      {
        responseType: 'blob'
      }
    )
    const blob = new Blob([data], { type: 'application/pdf' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `flat-deal-application-${applicationId}.pdf`
    link.click()
  }
}
