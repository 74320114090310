import http from '../http.js'

export default {
  fetchDocumentMeta: async (params) => {
    const { data } = await http.post('api/private/v1/media/document-meta', params)
    return data.data
  },
  async validateKeywords(params) {
    this.validateKeywords.abort?.()
    const { data } = await http.post('api/private/v1/ads/validate-keywords', params, {
      cancelToken: new http.CancelToken((abort) => {
        this.validateKeywords.abort = abort
      })
    })
    return data.data
  },
  forCampaignForm: async (params) => {
    const { data } = await http.get('api/private/v1/ads', { params })
    return data.data
  },
  store: async (params) => {
    const { data } = await http.post('api/private/v1/ads', params)
    return data
  },
  setCreativeStatus: async ({ adId, creativeId, creativeStatus }) => {
    const { data } = await http.post(
      `/api/private/v1/ads/${adId}/creatives/${creativeId}/${creativeStatus}`
    )

    return data.data
  },
  preview: async (params) => {
    const { data } = await http.get('/api/private/v1/ads/preview', { params })
    return data
  },
  async table(params) {
    this.table.abort?.()
    const { data } = await http.get('/api/private/v1/ads/table', {
      params,
      cancelToken: new http.CancelToken((abort) => {
        this.table.abort = abort
      })
    })
    return data
  },
  show: async (adId) => {
    const { data } = await http.get(`/api/private/v1/ads/${adId}`)
    return data.data
  },
  update: async (params, adId) => {
    const { data } = await http.put(`/api/private/v1/ads/${adId}`, params)
    return data
  },
  delete: async (adId) => {
    await http.delete(`/api/private/v1/ads/${adId}`)
  },
  creativeTokens: async () => {
    const { data } = await http.get('/api/private/v1/ads/creative-tokens')
    return data.data
  }
}
