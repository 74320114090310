import urlFormatter from '../url-formatter.js'
import http from '../http.js'

const getURL = urlFormatter({
  show: '/api/private/v1/week-spent'
})

export default {
  show() {
    const endpoint = getURL('show')
    return http.get(endpoint).then((response) => response.data.data)
  },
  getURL
}
