import AdFormat from '@/services/classes/AdFormat.js'

export default class PlacementType {
  adFormat = new AdFormat()
  id = null
  name = ''
  parent = {
    id: null
  }

  constructor(data) {
    if (!data) return

    this.adFormat = new AdFormat(data.ad_format)
    this.id = data.id ?? null
    this.name = data.name ?? ''
    this.parent = {
      id: data.parent?.id ?? null
    }
  }
}
