<template>
  <v-flex v-if="serverTime" shrink class="pr-2 ml-2">
    <div class="text-body-1 font-weight-light">
      UTC: {{ formatedDate }}
    </div>
  </v-flex>
</template>

<script>
  import moment from 'moment'
  import { mapState } from 'vuex'

  export default {
    name: 'ServerTime',
    data() {
      return {
        timestamp: ''
      }
    },
    computed: {
      ...mapState('settings', ['serverTime']),
      formatedDate() {
        return moment(this.timestamp).format('MM.DD HH:mm:ss')
      }
    },
    watch: {
      serverTime: {
        immediate: true,
        handler() {
          if (this.serverTime) {
            this.timestamp = moment(this.serverTime).valueOf()
          }
        }
      }
    },
    created() {
      const interval = setInterval(() => {
        this.timestamp = +this.timestamp + 1000
      }, 1000)

      this.$on('hook:beforeDestroy', () => {
        clearInterval(interval)
      })
    }
  }
</script>
