import http from '../http.js'
import urlFormatter from '../url-formatter.js'
import ValidationError from '@/services/classes/validation-error.js'

const getURL = urlFormatter({
  show: '/api/private/v1/networks',
  store: '/api/private/v1/networks'
})

export default {
  show() {
    const endpoint = getURL('show')
    return http.get(endpoint).then((response) => response.data.data)
  },
  store({
    adFormats, platform, serverLocation, openRtb, xml, ipv6Support, dsp, websiteLink
  }) {
    const endpoint = getURL('store')
    return http
      .post(endpoint, {
        ad_formats: adFormats,
        platform,
        server_location: serverLocation,
        open_rtb: openRtb,
        xml,
        ipv6_support: ipv6Support,
        dsp,
        website_link: websiteLink
      })
      .catch((error) => {
        const errors = error.response?.data?.errors
        if (errors) {
          throw new ValidationError({
            adFormats: errors.ad_formats,
            platform: errors.platform,
            serverLocation: errors.server_location,
            openRtb: errors.open_rtb,
            xml: errors.xml,
            ipv6Support: errors.ipv6_support,
            dsp: errors.dsp,
            websiteLink: errors.website_link
          })
        } else {
          throw error
        }
      })
  },
  billingTypes: async () => {
    const { data } = await http.get('/api/private/v1/networks/billing-types')
    return data.data
  },
  getURL
}
