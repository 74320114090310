export default function objectAssignByDictionary(to, from, dictionary = {}) {
  Object.entries(from).forEach(([fromKey, value]) => {
    const toKey = dictionary[fromKey]
    if (toKey) {
      to[toKey] = value
    } else {
      to[fromKey] = value
    }
  })

  return to
}
