import store from '@/store/index.js'

// This is hack!
// Element <link rel="manifest" /> may be on page in some cases ¯\_(ツ)_/¯. We need to check it
export default function createManifest() {
  const { manifest } = store.state.settings
  const stringManifest = JSON.stringify(manifest)
  const blob = new Blob([stringManifest], { type: 'application/json' })
  const manifestURL = URL.createObjectURL(blob)
  const element = document.createElement('link')
  element.setAttribute('rel', 'manifest')
  element.setAttribute('href', manifestURL)
  document.querySelector('head').appendChild(element)
}
