export default {
  namespaced: true,
  state: {
    statuses: [
      { value: 'needs_edits', label: 'main.approval_statuses.needs_edits', color: 'info' },
      { value: 'pending', label: 'main.approval_statuses.pending', color: 'warning white--text' },
      { value: 'rejected', label: 'main.approval_statuses.rejected', color: 'error white--text' },
      { value: 'approved', label: 'main.approval_statuses.approved', color: 'primary' }
    ]
  },
  getters: {
    approvalStatusTypes: (state) => state.statuses,
    adsApprovalStatusTypes: (state) => [
      ...state.statuses,
      { value: '', label: 'main.approval_statuses.waiting_for_campaign', color: 'cpm-color white--text' }
    ]
  }
}
