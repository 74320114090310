import Vuex from 'vuex'
import Vue from 'vue'
import collections from './collectionsModule/index.js'
import campaigns from './campaigns.js'
import approvables from './approvables.js'
import notification from './notification.js'
import rtb from './rtb.js'
import settings from './settings.js'
import trafficChartModule from './trafficChart/index.js'
import campaignsStatistics from './campaigns-statistics.js'
import dashboardModule from './dashboard/index.js'
import login from './login.js'
import registerModule from '@/store/register/index.js'
import personalModule from '@/store/personal/index.js'
import adFormModule from '@/store/adForm/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    personal: personalModule,
    adForm: adFormModule,
    campaigns,
    approvables,
    notification,
    rtb,
    settings,
    trafficChart: trafficChartModule,
    campaignsStatistics,
    dashboard: dashboardModule,
    login,
    register: registerModule,
    collections
  }
})
