import http from '../http.js'

export default {
  cancelRequestAvailableTrafficByCountry: null,
  cancelRequestAvailableTrafficForCampaign: null,
  availableTrafficByCountry({
    adFormat,
    pricingModel,
    countriesIds,
    premiumSitesIds,
    isPremiumSites,
    selectRotationAdType,
    trafficTypeId,
    operatingSystemsIds
  }) {
    this.cancelRequestAvailableTrafficByCountry?.()

    const siteTarget = () => isPremiumSites && {
      site_target: {
        site_ids: premiumSitesIds,
        accept: true,
        premium: true
      }
    }

    return http
      .post(
        '/api/private/v1/available-traffic-by-country',
        {
          ad_format: adFormat.type,
          ad_format_id: adFormat.id,
          pricing_model: pricingModel,
          ad_type_type: selectRotationAdType,
          targets: {
            country_target: {
              country_ids: countriesIds
            },
            ...siteTarget(),
            traffic_type_target: {
              traffic_type_id: trafficTypeId
            },
            operating_system_target: {
              operating_system_ids: operatingSystemsIds
            }
          }
        },
        {
          cancelToken: new http.CancelToken((abort) => {
            this.cancelRequestAvailableTrafficByCountry = abort
          })
        }
      )
      .then((response) => response.data.data)
  },
  async availableTrafficForCampaign(params) {
    this.cancelRequestAvailableTrafficForCampaign?.()

    const { data } = await http.post('/api/private/v1/available-traffic', params, {
      cancelToken: new http.CancelToken((abort) => {
        this.cancelRequestAvailableTrafficForCampaign = abort
      })
    })
    return data.data
  }
}
