const getters = {
  getRegisterData(state) {
    return {
      account_type: state.accountType,
      name: state.name,
      email: state.email,
      password: state.password,
      password_confirmation: state.passwordConfirmation,
      company_name: state.companyName,
      vat: state.valueAddedTax,
      company_address: state.companyAddress,
      company_site: state.companySite,
      additional_info: state.additionalInfo,
      'g-recaptcha-response': state.gRecaptchaResponse,
      topic: 'Sign Up form',
      messengers: state.selectedMessengers.map((messenger) => ({
        messenger_id: messenger.id,
        nickname: messenger.nickname ?? null
      }))
    }
  }
}

export default getters
