var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{class:['d-flex justify-space-between', { 'full-width': _vm.breakpoint.xsOnly }],attrs:{"app":"","dense":!_vm.breakpoint.xsOnly,"color":"white"}},[_c('v-toolbar-title',{staticClass:"d-flex d-lg-none"},[_c('v-app-bar-nav-icon',{staticClass:"mr-1",attrs:{"light":""},on:{"click":function($event){$event.stopPropagation();return _vm.sidebarHandler()}}},[_c('v-icon',{attrs:{"size":"25","color":"primary"}},[_vm._v(" $burger-menu ")])],1)],1),_c('div',{staticClass:"d-flex align-center full-width"},[_c('server-time',{staticClass:"d-none d-lg-flex"}),_c('div',{staticClass:"d-flex align-center"},[(_vm.isBeta && _vm.fullManageIsApproved)?_c('div',{staticClass:"d-flex align-center text-capitalize px-1 px-sm-0 px-md-1 mr-lg-3 ml-sm-0"},[_c('v-icon',{attrs:{"size":"18","color":"primary"}},[_vm._v(" $check ")]),(!_vm.isMobile)?_c('span',{staticClass:"d-flex d-md-flex font-weight-bold text-sm-caption text-md-subtitle-2 text-truncate primary--text ml-1"},[_vm._v(_vm._s(_vm.$t('main.full_manage.full_managed_title')))]):_vm._e()],1):_vm._e(),(_vm.isImpersonating)?_c('v-btn',{staticClass:"impersonate px-1 px-sm-0 px-md-1 mr-lg-3 ml-4 ml-sm-0 mr-sm-4",attrs:{"small":"","depressed":"","outlined":"","color":"error"}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" $warning ")]),(!_vm.isMobile)?_c('span',{staticClass:"d-flex d-md-flex ml-1"},[_vm._v("impersonate")]):_vm._e()],1):_vm._e()],1),_c('div',{class:[
        'd-flex align-center justify-space-between ml-0 ml-lg-auto',
        { 'full-width': _vm.breakpoint.mdAndDown }
      ]},[_c('div',{staticClass:"d-none d-sm-flex align-center ml-sm-auto mx-md-auto ml-lg-15"},[(_vm.adNetwork.settings.spentThisWeek)?_c('spent-this-week',{staticClass:"d-none d-md-flex align-center order-md-first order-lg-0 mr-md-8 mr-lg-16 pr-2"}):_vm._e(),(_vm.adFundsIsShown)?_c('c-btn',{staticClass:"text-button mr-1 mr-md-4",attrs:{"depressed":"","small":"","color":"primary","to":{ name: 'AddFunds' },"label":_vm.$t('main.sidebar.add_funds').toUpperCase(),"icon-props":{
            icon: '$plus',
            size: 12
          }}}):_vm._e(),_c('user-balance')],1),_c('v-spacer',{staticClass:"d-flex d-sm-none"}),_c('div',{staticClass:"d-flex align-center ml-3 ml-lg-15"},[(_vm.adNetworkHasSectionNotifications)?_c('router-link',{staticClass:"px-3 py-6",attrs:{"to":{ name: 'Notification' }}},[_c('v-icon',{attrs:{"color":_vm.isShowNotificationBell ? 'primary' : 'secondary-darken',"size":"18"}},[_vm._v(" $bells ")])],1):_vm._e(),_c('dropdown-menu',{staticClass:"ml-3 ml-md-0"})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }