import Cookies from 'js-cookie'
import settings from '@/settings.js'
import getDomainName from '@/services/utils/get-domain-name.js'

export default {
  created() {
    const { user } = this.$auth
    Cookies.set(settings.rememberUserCookieKey, user.name, {
      expires: 7,
      domain: getDomainName(window.location.host)
    })
  }
}
