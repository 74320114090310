export default class PopunderCreative {
  id = null
  priority = null
  url = ''

  constructor(data) {
    if (!data) return

    this.id = data.id ?? null
    this.priority = data.priority ?? null
    this.url = data.url ?? ''
  }
}
