<template>
  <div class="d-flex align-center">
    <c-btn
      :loading="loading"
      icon
      color="primary"
      small
      class="mr-0"
      :icon-props="{
        icon: '$update',
        size: '18'
      }"
      @click="update()"
    />
    <router-link tag="div" :to="redirectLink">
      <span class="text-subtitle-1 global-cursor-pointer"> ${{ balance }} </span>
    </router-link>
  </div>
</template>

<script>
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { mapState } from 'vuex'
  import guardSections from '@/types/guard-sections.js'

  export default {
    name: 'UserBalance',
    components: {
      CBtn
    },
    data() {
      return {
        loading: false,
        interval: null
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork']),
      user() {
        return this.$auth.user
      },
      balance() {
        if (!this.user) {
          return '-'
        }

        return toFixedByDefault(this.user.balance)
      },
      adNetworkHasSectionFinances() {
        return this.adNetwork.guardSections.some(({ name }) => name === guardSections.FINANCES)
      },
      redirectLink() {
        return this.adNetworkHasSectionFinances ? { name: 'Finances' } : ''
      }
    },
    created() {
      this.createUpdateInterval()
    },
    beforeDestroy() {
      clearInterval(this.interval)
    },
    methods: {
      update() {
        clearInterval(this.interval)

        this.loading = true
        this.$auth.fetchUser().finally(() => {
          this.loading = false
          this.createUpdateInterval()
        })
      },
      createUpdateInterval() {
        this.interval = setInterval(() => {
          this.update()
        }, 30 * 60 * 1000)
      }
    }
  }
</script>
