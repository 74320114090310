export default class CampaignGroup {
  id = null
  name = ''

  constructor(data) {
    if (!data) return

    this.id = data.id
    this.name = data.name
  }
}
