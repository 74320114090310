import { datatableFormater } from '@clickadilla/datatable-formatter'
import http from '../http.js'

export default {
  async fetchMobileApps(params, { campaignId }) {
    this.fetchMobileApps.abort?.()
    try {
      const response = await http.get('/api/private/v1/mobile-apps', {
        params: { ...datatableFormater(params), campaign_id: campaignId || null },
        cancelToken: new http.CancelToken((abort) => {
          this.fetchMobileApps.abort = abort
        })
      })
      return response.data
    } catch {
      this.$showErrorNotification('Error request mobile apps')
      return undefined
    }
  }
}
