export default {
  namespaced: true,
  state: {
    loading: false,
    errors: {
      email: null,
      password: null
    },
    email: '',
    password: '',
    code: '',
    googleCode: '',
    resetDialogIsShown: false,
    authenticationMethod: '',
    codeExpirationTime: '',
    codeSentAt: '',
    isExpired: false
  },
  mutations: {
    SET_LOADING: (state, isLoading) => {
      state.loading = isLoading
    },
    SET_ERRORS: (state, errors) => {
      state.errors = errors
    },
    RESET_EMAIL_ERRORS: (state, emailErrors) => {
      state.errors.email = emailErrors
    },
    RESET_PASSWORD_ERRORS: (state, passwordErrors) => {
      state.errors.password = passwordErrors
    },
    RESET_CODE_ERRORS: (state, codeErrors) => {
      state.errors.code = codeErrors
    },
    SET_EMAIL: (state, email) => {
      state.email = email
    },
    SET_PASSWORD: (state, password) => {
      state.password = password
    },
    SET_RESET_DIALOG_IS_SHOWN: (state, resetDialogIsShown) => {
      state.resetDialogIsShown = resetDialogIsShown
    },
    SET_CODE: (state, code) => {
      state.code = code
    },
    SET_GOOGLE_CODE: (state, googleCode) => {
      state.googleCode = googleCode
    },
    SET_AUTHENTICATION_METHOD: (state, authenticationMethod) => {
      state.authenticationMethod = authenticationMethod
    },
    SET_CODE_EXPIRATION_TIME: (state, codeExpirationTime) => {
      state.codeExpirationTime = codeExpirationTime
    },
    SET_CODE_SENT_AT: (state, codeSentAt) => {
      state.codeSentAt = codeSentAt
    },
    SET_IS_EXPIRED: (state, isExpired) => {
      state.isExpired = isExpired
      if (isExpired) {
        state.code = ''
        state.errors.code = []
      }
    }
  },
  actions: {
    resetEmailErrors({ commit }) {
      commit('RESET_EMAIL_ERRORS', null)
    },
    resetPasswordErrors({ commit }) {
      commit('RESET_PASSWORD_ERRORS', null)
    },
    setEmail({ commit }, email) {
      commit('SET_EMAIL', email)
    },
    setPassword({ commit }, password) {
      commit('SET_PASSWORD', password)
    },
    setCode({ commit }, code) {
      commit('SET_CODE', code)
    },
    setAuthenticationMethod({ commit }, authenticationMethod) {
      commit('SET_AUTHENTICATION_METHOD', authenticationMethod)
    },
    setGoogleCode({ commit }, googleCode) {
      commit('SET_GOOGLE_CODE', googleCode)
    },
    setResetDialogIsShown({ commit }, resetDialogIsShown) {
      commit('SET_RESET_DIALOG_IS_SHOWN', resetDialogIsShown)
    },
    setIsExpired({ commit }, isExpired) {
      commit('SET_IS_EXPIRED', isExpired)
    },
    setCodeSentAt({ commit }, codeSentAt) {
      commit('SET_CODE_SENT_AT', codeSentAt)
    },
    setCodeExpirationTime({ commit }, codeExpirationTime) {
      commit('SET_CODE_EXPIRATION_TIME', codeExpirationTime)
    },
    setErrors({ commit }, errors) {
      commit('SET_ERRORS', errors)
    },
    setLoading({ commit }, loading) {
      commit('SET_LOADING', loading)
    },
    resetConfirmation({ commit }) {
      commit('RESET_CODE_ERRORS', null)
      commit('SET_CODE', '')
      commit('SET_GOOGLE_CODE', '')
      commit('SET_AUTHENTICATION_METHOD', '')
      commit('SET_CODE_EXPIRATION_TIME', '')
      commit('SET_CODE_SENT_AT', '')
      commit('SET_IS_EXPIRED', false)
    },
    resetState({ commit, dispatch }) {
      commit('SET_LOADING', false)
      commit('SET_ERRORS', {})
      commit('SET_EMAIL', '')
      commit('SET_PASSWORD', '')
      commit('SET_RESET_DIALOG_IS_SHOWN', false)

      dispatch('resetConfirmation')
    }
  },
  getters: {
    codeFieldIsShown(state) {
      return state.authenticationMethod && state.codeExpirationTime
    },
    loginParams(state) {
      return {
        email: state.email,
        password: state.password,
        ...state.code && { code: state.code },
        ...state.googleCode && { code: state.googleCode }
      }
    }
  }
}
