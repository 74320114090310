var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.manager)?_c('v-list',{staticClass:"account-manager"},[_c('v-list-item',{class:[
      'account-manager__switcher',
      {
        active: _vm.isActive,
        'ml-3': !_vm.isMiniSidebarShown
      }
    ],on:{"click":function($event){_vm.isActive = !_vm.isActive}}},[_c('v-list-item-action',{class:{ 'mr-2': _vm.$i18n.locale !== 'en' && !_vm.isMiniSidebarShown }},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-account-tie ")])],1),_c('v-list-item-title',{class:['custom-title', { 'black--text': _vm.isActive }]},[_vm._v(" "+_vm._s(_vm.$t('main.personal_manager'))+" ")])],1),_c('v-expand-transition',[(_vm.isActive)?_c('div',{staticClass:"pl-4 mt-3"},[_c('v-layout',{attrs:{"align-center":""}},[_c('v-avatar',{attrs:{"size":"60px"}},[_c('v-img',{attrs:{"src":_vm.managerAvatarUrl,"lazy-src":_vm.defaultAvatar,"alt":"avatar"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary-lighten"}})],1)]},proxy:true}],null,false,1494326885)})],1),_c('div',{staticClass:"account-manager__info ml-2"},[_c('div',{staticClass:"account-manager__name"},[_vm._v(" "+_vm._s(_vm.manager.name)+" ")]),_c('a',{staticClass:"text-caption",attrs:{"href":"#"},on:{"click":function($event){return _vm.$intercom.showNewMessage()}}},[_vm._v(" "+_vm._s(_vm.$t('main.live_chat'))+" ")])])],1)],1):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }