import { render, staticRenderFns } from "./PersonalManager.vue?vue&type=template&id=bea52bd4&scoped=true&"
import script from "./PersonalManager.vue?vue&type=script&lang=js&"
export * from "./PersonalManager.vue?vue&type=script&lang=js&"
import style0 from "./PersonalManager.vue?vue&type=style&index=0&id=bea52bd4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bea52bd4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VExpandTransition,VIcon,VImg,VLayout,VList,VListItem,VListItemAction,VListItemTitle,VProgressCircular,VRow})
