import types from '@/store/register/common/types.js'

const actions = {
  setAccountType({ commit }, accountType) {
    commit(types.SET_ACCOUNT_TYPE, accountType)
  },
  setName({ commit }, name) {
    commit(types.SET_NAME, name)
  },
  setEmail({ commit }, email) {
    commit(types.SET_EMAIL, email)
  },
  setPassword({ commit }, password) {
    commit(types.SET_PASSWORD, password)
  },
  setPasswordConfirmation({ commit }, passwordConfirmation) {
    commit(types.SET_PASSWORD_CONFIRMATION, passwordConfirmation)
  },
  setCompanyName({ commit }, companyName) {
    commit(types.SET_COMPANY_NAME, companyName)
  },
  setValueAddedTax({ commit }, valueAddedTax) {
    commit(types.SET_VALUE_ADDED_TAX, valueAddedTax)
  },
  setCompanyAddress({ commit }, companyAddress) {
    commit(types.SET_COMPANY_ADDRESS, companyAddress)
  },
  setCompanySite({ commit }, companySite) {
    commit(types.SET_COMPANY_SITE, companySite)
  },
  setAdditionalInfo({ commit }, additionalInfo) {
    commit(types.SET_ADDITIONAL_INFO, additionalInfo)
  },
  setRecaptcha({ commit }, recaptcha) {
    commit(types.SET_RECAPTCHA, recaptcha)
  },
  setErrors({ commit }, errors) {
    commit(types.SET_ERRORS, errors)
  },
  resetCompanyFields({ commit }) {
    commit(types.SET_COMPANY_NAME, '')
    commit(types.SET_VALUE_ADDED_TAX, '')
    commit(types.SET_COMPANY_ADDRESS, '')
    commit(types.SET_COMPANY_SITE, '')
    commit(types.SET_ADDITIONAL_INFO, '')
  },
  resetError({ commit }, nameError) {
    commit(types.RESET_ERROR, nameError)
  },
  setSelectedMessengers({ commit }, selectedMessenger) {
    commit(types.SET_SELECTED_MESSENGERS, selectedMessenger)
  }
}

export default actions
