export default class MotionBanner {
  faderColor = ''
  buttonColor = ''
  buttonHoverColor = ''
  buttonTextColor = ''
  buttonTextHoverColor = ''
  buttonText = ''
  titleText = ''
  descriptionText = ''
  additionalText = ''
  textColor = ''

  constructor(data) {
    if (!data) return

    this.faderColor = data.fader_color ?? ''
    this.buttonColor = data.button_color ?? ''
    this.buttonHoverColor = data.button_hover_color ?? ''
    this.buttonTextColor = data.button_text_color ?? ''
    this.buttonTextHoverColor = data.button_text_hover_color ?? ''
    this.buttonText = data.button_text ?? ''
    this.titleText = data.title_text ?? ''
    this.descriptionText = data.description_text ?? ''
    this.additionalText = data.additional_text ?? ''
    this.textColor = data.text_color ?? ''
  }
}
