import ProviderPlan from './ProviderPlan.js'

export default class SubscriptionPlan {
  id = null
  paidUntil = ''
  plan = new ProviderPlan()
  status = ''

  constructor(data) {
    if (!data) return

    this.id = data.id ?? null
    this.paidUntil = data.paid_until ?? ''
    this.plan = new ProviderPlan(data.plan)
    this.status = data.status ?? ''
  }
}
