export default {
  install(_Vue) {
    const gsc = new _Vue({
      data() {
        return {
          inited: false
        }
      },
      methods: {
        openWidget(widgetId) {
          window.gsc('show', widgetId)
        },
        closeWidget(widgetId) {
          window.gsc('close', widgetId)
        },
        setParams(params) {
          window.gsc('params', params)
        },
        async init(gscSettings) {
          // eslint-disable-next-line func-names
          window.gsc = window.gsc || function () {
            // eslint-disable-next-line prefer-rest-params
            (window.gsc.q = window.gsc.q || []).push(arguments)
          }
          if (!this.inited) {
            await this.loadScript(gscSettings)
            this.setParams({
              name: gscSettings.name,
              email: gscSettings.email,
              user_id: gscSettings.userId
            })
            this.inited = true
          }
        },
        loadScript({ gscId }) {
          return new Promise((resolve) => {
            const script = document.createElement('script')
            script.async = true
            script.src = `https://l.getsitecontrol.com/${gscId}.js`
            script.onload = resolve
            document.body.appendChild(script)
          })
        }
      }
    })

    _Vue.prototype.$gsc = gsc
  }
}
