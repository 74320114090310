import http from '../http.js'

export default {
  async fetchNotifications(params) {
    this.fetchNotifications.abort?.()

    const { data } = await http.get('/api/private/v1/notifications', {
      params,
      cancelToken: new http.CancelToken((abort) => {
        this.fetchNotifications.abort = abort
      })
    })
    return data
  },
  readNotifications: async (notificationId) => {
    const { data } = await http.post(`api/private/v1/notifications/${notificationId}/read`)
    return data
  },
  readAllNotifications: async () => {
    await http.post('api/private/v1/notifications/read-all')
  },
  removeNotifications: async () => {
    await http.delete('api/private/v1/notifications')
  },
  fetchTooltipsNotifications: async () => {
    const { data } = await http.get('api/private/v1/campaign-tooltips')
    return data.data
  }
}
