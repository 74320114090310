import { adFormats } from '@clickadilla/components/constants/ad-formats.js'
import { pricingModelTypes } from '@clickadilla/components/constants/pricing-model-types.js'
import campaignGroupsRepository from '@/services/repositories/campaign-groups-repository.js'
import handleErrors from '@/services/handleErrors.js'

export default {
  namespaced: true,
  state: {
    campaignGroups: [],
    groupId: null,
    campaignGroupsAreLoading: false,
    statuses: [
      {
        value: 'active',
        label: 'main.statuses.active',
        color: 'primary--text',
        labelColor: 'black--text',
        icon: '$active',
        iconSize: 20,
        iconColor: 'primary'
      },
      {
        value: 'paused',
        label: 'main.statuses.paused',
        color: 'warning--text',
        labelColor: 'black--text',
        icon: '$pause-2',
        iconColor: 'warning',
        iconSize: 20
      },
      {
        value: 'archived',
        label: 'main.statuses.archived',
        color: 'secondary-darken--text',
        labelColor: 'black--text',
        icon: '$archived-2',
        iconColor: 'secondary-darken',
        iconSize: 20
      }
    ],
    adFormates: [
      { label: 'Popunder', value: adFormats.POPUNDER },
      { label: 'Banner', value: adFormats.BANNER },
      { label: 'In-stream', value: adFormats.IN_STREAM_AD },
      { label: 'Native ad', value: adFormats.NATIVE_AD },
      { label: 'Web push', value: adFormats.WEB_PUSH },
      { label: 'Notification widget', value: adFormats.IOS_CALENDAR },
      { label: 'Gallery ', value: adFormats.GALLERY },
      { label: 'Direct link', value: adFormats.DIRECT_LINK },
      { label: 'Interstitial ', value: adFormats.INTERSTITIAL },
      { label: 'In page', value: adFormats.IN_PAGE_AD }
    ],
    pricingModels: [
      {
        label: 'CPC',
        value: pricingModelTypes.CPC,
        color: 'cpc-color',
        labelColor: 'black--text'
      },
      {
        label: 'CPM',
        value: pricingModelTypes.CPM,
        color: 'cpm-color',
        labelColor: 'black--text'
      },
      {
        label: 'CPA',
        value: pricingModelTypes.CPA,
        color: 'cpa-color',
        labelColor: 'black--text'
      },
      {
        label: 'CPM GOAL',
        value: pricingModelTypes.CPM_GOAL,
        color: 'cpm-goal-color',
        labelColor: 'black--text'
      },
      {
        label: 'CPC GOAL',
        value: pricingModelTypes.CPC_GOAL,
        color: 'cpc-goal-color',
        labelColor: 'black--text'
      }
    ],
    limitStatuses: [
      {
        value: 'hourly',
        label: 'campaign.limits_statuses.hourly_limits'
      },
      {
        value: 'daily',
        label: 'campaign.limits_statuses.daily_limits'
      },
      {
        value: 'total',
        label: 'campaign.limits_statuses.total_limits'
      }
    ],
    cpaStatuses: [
      { value: 'disabled', label: 'main.cpa_statuses.disabled', color: 'default' },
      { value: 'testing', label: 'main.cpa_statuses.testing', color: 'warning white--text' },
      { value: 'test unsuccessful', label: 'main.cpa_statuses.failed', color: 'error white--text' },
      { value: 'test successful', label: 'main.cpa_statuses.succeed', color: 'primary' }
    ],
    tablePerPagePagination: [15, 25, 50, -1]
  },
  getters: {},
  mutations: {
    SET_CAMPAIGN_GROUP_ID: (state, value) => {
      state.groupId = value
    },
    CAMPAIGN_GROUPS_ARE_LOADING: (state, value) => {
      state.campaignGroupsAreLoading = value
    },
    SET_CAMPAIGN_GROUP_SELECT: (state, value) => {
      state.campaignGroups = value
    }
  },
  actions: {
    async fetchCampaignGroups({ commit }) {
      commit('CAMPAIGN_GROUPS_ARE_LOADING', true)
      try {
        const response = await campaignGroupsRepository.index()
        commit('SET_CAMPAIGN_GROUP_SELECT', response)
      } catch (error) {
        handleErrors(error)
      }
      commit('CAMPAIGN_GROUPS_ARE_LOADING', false)
    },
    setCampaignGroupId({ commit }, value) {
      commit('SET_CAMPAIGN_GROUP_ID', value)
    }
  }
}
