import http from '../http.js'

export default {
  store: async (params) => {
    const { data } = await http.post('/api/private/v1/invoices', params)
    return data.data
  },
  subscription: async (params) => {
    await http.put('/api/private/v1/payment-subscriptions', params)
  },
  delete: async (id) => {
    await http.delete(`/api/private/v1/invoices/${id}`)
  },
  unsubscription: async () => {
    await http.delete('/api/private/v1/payment-subscriptions')
  },
  async update({ invoiceId, params }) {
    await http.put(`/api/private/v1/invoices/${invoiceId}`, params)
  },
  document: async (invoiceId) => {
    const { data } = await http.get(`/api/private/v1/invoices/${invoiceId}/document`, {
      responseType: 'blob'
    })
    const blob = new Blob([data], { type: 'application/pdf' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `invoice-${invoiceId}.pdf`
    link.click()
  },
  async totals(params) {
    this.totals.abort?.()
    const { data } = await http.get('/api/private/v1/invoices/total-amount', {
      params,
      cancelToken: new http.CancelToken((abort) => {
        this.totals.abort = abort
      })
    })
    return data.data
  }
}
