import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {}
})

const loadedLocales = {
  en: [],
  ru: [],
  de: [],
  es: [],
  jp: []
}

function setI18nLanguage(lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  localStorage.locale = lang
}
export async function loadNewLocales(language, locales) {
  let newLocales = {}
  await Promise.all(
    locales.map(async (locale) => {
      const translates = locale === 'campaign_form.json'
        ? await import(`@clickadilla/components/CampaignForm/locales/${language}.json`)
        : await import(`@/locales/${language}/${locale}`)
      newLocales = {
        ...newLocales,
        ...{
          [locale.replace(/\.[^.]*$/, '')]: translates
        }
      }
    })
  )
  return newLocales
}

export async function loadLanguageAsync(language, localeFiles = ['main.json']) {
  const lang = language || 'en'

  if (!loadedLocales[lang].length) {
    const main = await import(`@/locales/${lang}/main.json`)
    i18n.setLocaleMessage(lang, { main })
    loadedLocales[lang].push('main')
  }

  const unloadedLocales = localeFiles.filter((localeItem) => !loadedLocales[lang].includes(localeItem))

  if (unloadedLocales.length) {
    const newLocales = await loadNewLocales(lang, unloadedLocales)
    i18n.setLocaleMessage(lang, {
      ...i18n.messages[lang],
      ...newLocales
    })
    localeFiles.forEach((newLocale) => {
      loadedLocales[lang].push(newLocale)
    })
  }

  if (i18n.locale !== lang) {
    setI18nLanguage(lang)
  }
}
