import CategoryGroup from '@/services/classes/CategoryGroup.js'

export default class TrafficQuality {
  categoryGroup = new CategoryGroup()
  score = null
  type = ''

  constructor(data) {
    if (!data) return

    this.categoryGroup = new CategoryGroup(data.category_group)
    this.score = data.score ?? null
    this.type = data.type ?? ''
  }
}
