import http from '../http.js'
import urlFormatter from '../url-formatter.js'
import ValidationError from '@/services/classes/validation-error.js'
import { createRtbEndpointFromResource } from '@/services/classes/createRtbEndpoint.js'

const getURL = urlFormatter({
  show: '/api/private/v1/feeds/{id}',
  store: '/api/private/v1/feeds',
  update: '/api/private/v1/feeds/{id}'
})

function createRequest({ name, endpoint }) {
  return {
    name,
    endpoint
  }
}

function handleError(errors) {
  return Promise.reject(
    new ValidationError({
      name: errors.name,
      endpoint: errors.endpoint
    })
  )
}

export default {
  async table(params) {
    this.table.abort?.()
    const { data } = await http.get('/api/private/v1/feeds', {
      params,
      cancelToken: new http.CancelToken((abort) => {
        this.table.abort = abort
      })
    })
    return data
  },
  show(id) {
    const endpoint = getURL('show', { id })
    return http.get(endpoint).then((response) => createRtbEndpointFromResource(response.data.data))
  },
  activate: async (endpointId) => {
    await http.post(`/api/private/v1/feeds/${endpointId}/activate`)
  },
  pause: async (endpointId) => {
    await http.post(`/api/private/v1/feeds/${endpointId}/pause`)
  },
  delete: async (endpointId) => {
    await http.delete(`/api/private/v1/feeds/${endpointId}`)
  },
  store(formData) {
    const endpoint = getURL('store')
    return http.post(endpoint, createRequest(formData)).catch((error) => {
      if (error.response && error.response.data && error.response.data.errors) {
        return handleError(error.response.data.errors)
      }

      throw error
    })
  },
  update(id, formData) {
    const endpoint = getURL('update', { id })
    return http.patch(endpoint, createRequest(formData)).catch((error) => {
      if (error.response && error.response.data && error.response.data.errors) {
        return handleError(error.response.data.errors)
      }

      throw error
    })
  },
  getURL
}
