import moment from 'moment'

export default function getInitialDates(dayOffset) {
  const end = moment().format('YYYY-MM-DD')
  const start = moment().add({ days: dayOffset }).format('YYYY-MM-DD')

  return {
    start,
    end
  }
}
