import http from '../http.js'
import urlFormatter from '../url-formatter.js'
import ValidationError from '@/services/classes/validation-error.js'

const getURL = urlFormatter({
  deleteCard: '/api/private/v1/verifications/card',
  verifyCard: '/api/private/v1/verifications/card'
})

export default {
  delete: async () => {
    const endpoint = getURL('deleteCard')
    await http.delete(endpoint)
  },
  verify({ type, documents }) {
    const endpoint = {
      card: getURL('verifyCard')
    }[type]

    return http.post(endpoint, { type, documents }).catch((error) => {
      const errors = Object.entries(error.response.data.errors).reduce(
        (acc, [paramName, paramErrors]) => {
          let localParamName = paramName

          if (paramName.match(/^documents\.io/)) {
            localParamName = 'io'
          } else if (paramName.match(/^documents\.passport_and_paper/)) {
            localParamName = 'passport_and_paper'
          } else if (paramName.match(/^documents\.passport/)) {
            localParamName = 'passport'
          } else if (paramName.match(/^documents\.photo_of_card/)) {
            localParamName = 'photo_of_card'
          }

          acc[localParamName] = [...(acc[localParamName] || []), ...paramErrors]

          return acc
        },
        {}
      )

      throw new ValidationError(errors)
    })
  }
}
