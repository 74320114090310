export class RtbEndpoint {
  constructor(data) {
    Object.assign(this, data)
  }
}

export default function createRtbEndpoint(data) {
  return new RtbEndpoint({
    id: data.id || 0,
    name: data.name || '',
    endpoint: data.endpoint || ''
  })
}

export function createRtbEndpointFromResource(resource) {
  return createRtbEndpoint({
    name: resource.name,
    endpoint: resource.endpoint
  })
}
