export default {
  SET_ACCOUNT_TYPE: 'SET_ACCOUNT_TYPE',
  SET_NAME: 'SET_NAME',
  SET_EMAIL: 'SET_EMAIL',
  SET_PASSWORD: 'SET_PASSWORD',
  SET_PASSWORD_CONFIRMATION: 'SET_PASSWORD_CONFIRMATION',
  SET_COMPANY_NAME: 'SET_COMPANY_NAME',
  SET_VALUE_ADDED_TAX: 'SET_VALUE_ADDED_TAX',
  SET_COMPANY_ADDRESS: 'SET_COMPANY_ADDRESS',
  SET_COMPANY_SITE: 'SET_COMPANY_SITE',
  SET_ADDITIONAL_INFO: 'SET_ADDITIONAL_INFO',
  SET_RECAPTCHA: 'SET_RECAPTCHA',
  SET_ERRORS: 'SET_ERRORS',
  RESET_ERROR: 'RESET_ERROR',
  SET_SELECTED_MESSENGERS: 'SET_SELECTED_MESSENGERS'
}
