import types from '@/store/register/common/types.js'

const mutations = {
  [types.SET_ACCOUNT_TYPE]: (state, accountType) => {
    state.accountType = accountType
  },
  [types.SET_NAME]: (state, name) => {
    state.name = name
  },
  [types.SET_EMAIL]: (state, email) => {
    state.email = email
  },
  [types.SET_PASSWORD]: (state, password) => {
    state.password = password
  },
  [types.SET_PASSWORD_CONFIRMATION]: (state, passwordConfirmation) => {
    state.passwordConfirmation = passwordConfirmation
  },
  [types.SET_COMPANY_NAME]: (state, companyName) => {
    state.companyName = companyName
  },
  [types.SET_VALUE_ADDED_TAX]: (state, valueAddedTax) => {
    state.valueAddedTax = valueAddedTax
  },
  [types.SET_COMPANY_ADDRESS]: (state, companyAddress) => {
    state.companyAddress = companyAddress
  },
  [types.SET_COMPANY_SITE]: (state, companySite) => {
    state.companySite = companySite
  },
  [types.SET_ADDITIONAL_INFO]: (state, additionalInfo) => {
    state.additionalInfo = additionalInfo
  },
  [types.SET_RECAPTCHA]: (state, gRecaptchaResponse) => {
    state.gRecaptchaResponse = gRecaptchaResponse
  },
  [types.SET_ERRORS]: (state, errors) => {
    state.errors = errors
  },
  [types.RESET_ERROR]: (state, nameError) => {
    state.errors[nameError] = []
  },
  [types.SET_SELECTED_MESSENGERS]: (state, selectedMessengers) => {
    state.selectedMessengers = selectedMessengers
  }
}

export default mutations
