/**
 * wrapper for window.dataLayer.push(data)
 * @param data {object}
 */
const gtmPush = (data) => {
  if (window.dataLayer) {
    window.dataLayer.push(data)
  }
}

export default gtmPush
