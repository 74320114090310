import campaignsStatisticsRepository from '@/services/repositories/campaigns-statistics-repository.js'
import { dashboardRepository } from '@/services/repository-factory.js'
import types from '@/store/dashboard/common/types.js'
import handleErrors from '@/services/handleErrors.js'

const actions = {
  setDateRange: async ({ commit, dispatch }, dateRange) => {
    commit(types.SET_DATE_RANGE_START, dateRange.start)
    commit(types.SET_DATE_RANGE_END, dateRange.end)
    if (dateRange.end) {
      await dispatch('fetchChartStatistics')
      await dispatch('fetchTableStatistics')
    }
  },
  setLineType({ commit }, lineType) {
    commit(types.SET_LINE_TYPE, lineType)
  },
  setColumnType({ commit }, columnType) {
    commit(types.SET_COLUMN_TYPE, columnType)
  },
  fetchChartStatistics: async ({ commit, state }) => {
    try {
      commit(types.SET_CHART_IS_LOADING, true)
      const chartStatistics = await campaignsStatisticsRepository.list({
        dates: state.dateRange,
        groupBy: 'date'
      })
      commit(types.SET_CHART_STATISTICS, chartStatistics)
    } catch (error) {
      handleErrors(error)
    }
    commit(types.SET_CHART_IS_LOADING, false)
  },
  fetchTableStatistics: async ({ commit, state }) => {
    try {
      commit(types.SET_TABLE_IS_LOADING, true)
      const tableStatistics = await campaignsStatisticsRepository.list({
        dates: state.dateRange,
        groupBy: 'campaign'
      })
      commit(types.SET_TABLE_STATISTICS, tableStatistics)
    } catch (error) {
      handleErrors(error)
    }
    commit(types.SET_TABLE_IS_LOADING, false)
  },
  fetchDashboard: async ({ commit }) => {
    commit(types.SET_DASHBOARD_IS_LOADING, true)
    try {
      const response = await dashboardRepository.fetchDashboard()
      commit(types.SET_COST, {
        todayCost: response.today_cost,
        yesterdayCost: response.yesterday_cost,
        currentMonthCost: response.current_month_cost,
        previousMonthCost: response.previous_month_cost
      })
      commit(types.SET_CAMPAIGN, {
        pendingCampaignsCount: response.pending_campaigns_count,
        activeCampaignsCount: response.active_campaigns_count,
        campaignsTotal: response.campaigns_total,
        pausedCampaignCount: response.paused_campaigns_count
      })
      commit(types.SET_BALANCE, response.balance)
      commit(types.SET_RESERVED_BALANCE, response.reserved_balance)
    } catch (error) {
      handleErrors(error)
    }
    commit(types.SET_DASHBOARD_IS_LOADING, false)
  }
}

export default actions
