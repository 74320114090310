export default function detectAdBlock() {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.async = 1
    script.src = 'https://js.wpadmngr.com/npc/sdk/wp-banners.js'
    script.onload = () => resolve(false)
    script.onerror = () => resolve(true)
    document.head.appendChild(script)
  })
}
