import Vue from 'vue'
import personalRepository from '@/services/repositories/personal-repository.js'
import types from '@/store/personal/common/types.js'
import handleErrors from '@/services/handleErrors.js'

const successNotify = (text) => Vue.prototype.$toast(text, {
  type: 'success',
  title: text
})

const actions = {
  async fetchUserProfile({ commit }) {
    commit(types.SET_FETCH_IS_LOADING, true)
    try {
      const data = await personalRepository.profile()
      commit(types.SET_ID, data.id)
      commit(types.SET_NAME, data.name)
      commit(types.SET_PHONE, data.phone)
      commit(types.SET_EMAIL, data.email)
      commit(types.SET_BALANCE, data.balance)
      commit(types.SET_ACCOUNT_TYPE, data.accountType)
      commit(types.SET_ACCOUNT_MANAGER, data.accountManager)
      commit(types.SET_COMPANY_NAME, data.companyName)
      commit(types.SET_COMPANY_ADDRESS, data.companyAddress)
      commit(types.SET_COMPANY_SITE, data.companySite)
      commit(types.SET_VAT, data.vat)
      commit(types.SET_ADDITIONAL_INFO, data.additionalInfo)
      commit(types.SET_AD_ACTIONS_NOTIFICATIONS, data.adActionsNotifications)
      commit(types.SET_CAMPAIGN_ACTIONS_NOTIFICATIONS, data.campaignActionsNotifications)
      commit(types.SET_CAMPAIGN_TOOLTIPS_NOTIFICATIONS, data.campaignTooltipsNotifications)
      commit(types.SET_TRAFFIC_PROVIDER_SUBSCRIPTION, data.trafficProviderSubscription)
      commit(types.SET_REFERRAL_PROGRAM_PERCENT, data.referralProgramPercent)
      commit(types.SET_IS_COMPANY_VERIFIED, data.isCompanyVerified)
      commit(types.SET_VERIFICATION_STATUSES, data.verificationStatuses)
      commit(types.SET_TWO_FACTOR_AUTHENTICATION_METHOD, data.twoFactorAuthenticationMethod)
      commit(types.SET_RECURRING_PAYMENTS_CUSTOMER, data.recurringPaymentsCustomer)
      commit(types.SET_USER_MESSENGERS, data.messengers)
      commit(types.SET_FULL_MANAGE_STATUS, data.fullManageStatus)
      commit(types.SET_ACTIONS, data.actions)
      const subscribeTelegram = data.messengers.find(({ name }) => name === 'Telegram')?.messenger_user_id ?? null
      commit(types.SET_SUBSCRIBE_TELEGRAM, subscribeTelegram)
    } catch (error) {
      handleErrors(error)
    }
    commit(types.SET_FETCH_IS_LOADING, false)
  },
  resetRecurringPaymentsCustomers({ commit }) {
    commit(types.SET_RECURRING_PAYMENTS_CUSTOMER, [])
  },
  changeUserName({ commit }, name) {
    commit(types.SET_NAME, name)
  },
  changePhone({ commit }, phone) {
    commit(types.SET_PHONE, phone)
  },
  setUserMessengers({ commit }, messengers) {
    commit(types.SET_USER_MESSENGERS, messengers)
  },
  removeUserMessenger({ commit }, removingMsgIndex) {
    commit(types.REMOVE_USER_MESSENGER, removingMsgIndex)
  },
  changeAccountType({ commit }, accountType) {
    commit(types.SET_ACCOUNT_TYPE, accountType)
  },
  changeCompanyName({ commit }, companyName) {
    commit(types.SET_COMPANY_NAME, companyName)
  },
  changeCompanyAddress({ commit }, companyAddress) {
    commit(types.SET_COMPANY_ADDRESS, companyAddress)
  },
  changeCompanySite({ commit }, companySite) {
    commit(types.SET_COMPANY_SITE, companySite)
  },
  changeVat({ commit }, vat) {
    commit(types.SET_VAT, vat)
  },
  changeAdditionalInfo({ commit }, additionalInfo) {
    commit(types.SET_ADDITIONAL_INFO, additionalInfo)
  },
  changeAdActionsNotifications({ commit }, adActionsNotifications) {
    commit(types.SET_AD_ACTIONS_NOTIFICATIONS, adActionsNotifications)
  },
  changeCampaignActionsNotifications({ commit }, campaignActionsNotifications) {
    commit(types.SET_CAMPAIGN_ACTIONS_NOTIFICATIONS, campaignActionsNotifications)
  },
  changeCampaignTooltipNotifications({ commit }, campaignTooltipsNotifications) {
    commit(types.SET_CAMPAIGN_TOOLTIPS_NOTIFICATIONS, campaignTooltipsNotifications)
  },
  changeTwoFactorAuthenticationMethod({ commit }, twoFactorAuthenticationMethod) {
    commit(types.SET_TWO_FACTOR_AUTHENTICATION_METHOD, twoFactorAuthenticationMethod)
  },
  async saveUserProfile({ commit, getters, dispatch }) {
    commit(types.SET_SAVE_IS_LOADING, true)
    try {
      await personalRepository.update(getters.getRequestPersonal)
      successNotify('Profile data updated successfully')
      commit(types.SET_ERRORS, {})
      await dispatch('fetchUserProfile')
    } catch (error) {
      const errors = error.response?.data?.errors
      if (errors) {
        const errorsMessages = {
          name: errors.name,
          accountType: errors.account_type,
          companyName: errors.company_name,
          companyAddress: errors.company_address,
          companySite: errors.company_site,
          vat: errors.vat,
          additionalInfo: errors.additional_info,
          adActionsNotifications: errors.notifications_about_ad_actions,
          campaignActionsNotifications: errors.notifications_about_campaign_actions,
          campaignTooltipsNotifications: errors.notifications_about_campaign_tooltips,
          twoFactorAuthenticationMethod: errors.two_factor_authentication_method
        }
        commit(types.SET_ERRORS, { ...errorsMessages, ...errors })
      }
    }
    commit(types.SET_SAVE_IS_LOADING, false)
  }
}

export default actions
