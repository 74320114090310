import Media from '@/services/classes/Media.js'

export default class NativeAdCreative {
  id = null
  image = new Media()
  isActive = true
  title = ''
  description = ''
  url = ''

  constructor(data) {
    if (!data) return

    this.id = data.id ?? null
    this.image = new Media(data.image)
    this.isActive = data.is_active ?? true
    this.title = data.title ?? ''
    this.description = data.description ?? ''
    this.url = data.url ?? ''
  }
}
