import Vue from 'vue'
import Toast from 'vue-toastification'
import VueAxios from 'vue-axios'
import VueClipboard from 'vue-clipboard2'
import VueSocialSharing from 'vue-social-sharing'
import App from './App.vue'
import vuetify from './plugins/vuetify/index.js'
import '@/assets/scss/global.scss'
import store from './store/index.js'
import router from './router.js'
import axios from '@/services/axios.js'
import settings from './settings.js'
import { i18n } from './i18n.js'
import globalMethods from './plugins/global-methods.js'
import globalComponents from './plugins/global-components.js'
import pwaInstall from './plugins/pwa-install.js'
import intercom from './plugins/intercom.js'
import tawk from '@/plugins/tawk.js'
import dashly from '@/plugins/dashly.js'
import adServer from '@/plugins/ad-server.js'
import getsitecontrol from '@/plugins/getsitecontrol.js'
import 'vue-toastification/dist/index.css'
import { saveQueryParamsToCookie } from '@/cookie-queries.js'
import '@/plugins/sentry.js'
import auth from '@/plugins/auth.js'
import setQueryParamsToSessionStorage from '@/services/utils/set-query-params-to-session-storage.js'

saveQueryParamsToCookie()
setQueryParamsToSessionStorage()

Vue.use(VueSocialSharing)
Vue.use(globalMethods)
Vue.use(pwaInstall)
Vue.use(globalComponents)
Vue.use(intercom)
Vue.use(tawk)
Vue.use(dashly)
Vue.use(adServer)
Vue.use(getsitecontrol)

Vue.use(Toast, {
  position: 'top-center',
  timeout: 2000
})

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.use(VueAxios, axios)

Vue.use(auth)

Vue.config.productionTip = false
new Vue({
  provide: {
    settings
  },
  vuetify,
  store,
  router,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
