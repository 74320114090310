<template>
  <c-select
    class="mb-10 px-0"
    hide-details
    :items="getLocales"
    :value="locale"
    item-text="label"
    item-value="value"
    item-slot
    :selection-slot="isMiniSidebarShown"
    :height="30"
    :menu-props="{
      contentClass: 'lang-menu',
      top: true,
      'nudge-top': '5px',
      offsetY: true
    }"
    background-color="secondary-darken"
    @change="setLocale($event)"
  >
    <template v-if="!isMiniSidebarShown" #prepend-inner>
      <v-icon
        :class="`${isMiniSidebarShown ? 'mr-n1' : 'mr-1'}`"
        :size="isMiniSidebarShown ? 16 : 24"
      >
        {{ getLocaleIcon() }}
      </v-icon>
    </template>
    <template v-if="isMiniSidebarShown" #selection="{ item }">
      <v-icon :size="16">
        {{ item.flagIcon }}
      </v-icon>
    </template>
    <template #item="{ item }">
      <v-icon>{{ item.flagIcon }}</v-icon>
      <div class="opacity-60 lang-menu-text ml-3">
        {{ item.label }}
      </div>
    </template>
  </c-select>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import { personalRepository } from '@/services/repository-factory.js'
  import { loadLanguageAsync } from '@/i18n.js'

  export default {
    name: 'LanguageSelect',
    components: { CSelect },
    computed: {
      ...mapState('settings', ['isMiniSidebarShown', 'locale']),
      ...mapGetters('settings', ['getLocales'])
    },
    watch: {
      locale: {
        immediate: true,
        async handler(newLocale) {
          await this.$auth.fetchUser()
          const userLocale = this.$auth.user?.locale
          if (userLocale !== newLocale) {
            await personalRepository.setLocale(newLocale)
            loadLanguageAsync(newLocale, this.$route.meta.localeFiles)
            await this.$auth.refresh()
            await this.fetchSettings(this.$auth.isAuth)
          }
        }
      }
    },
    methods: {
      ...mapActions('settings', ['setLocale', 'fetchSettings']),
      getLocaleIcon() {
        return this.getLocales.find(({ value }) => value === this.locale).flagIcon
      }
    }
  }
</script>

<style lang="scss">
  .lang-menu {
    .v-list {
      background-color: var(--v-secondary-darken-base);

      &-item {
        display: flex;
        align-items: center;
        color: var(--v-white-base) !important;

        &:hover {
          .lang-menu-text {
            color: var(--v-primary-lighten-base);
            opacity: 1;
          }
        }

        &--active {
          .lang-menu-text {
            opacity: 1;
            color: var(--v-white-base) !important;
          }

          &:before {
            display: none;
          }
        }
      }
    }
  }
</style>
