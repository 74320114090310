export default class Media {
  id = null
  src = ''
  mimeType = ''
  customProperties = {}
  documentUrl = ''

  constructor(data) {
    if (!data) return

    this.id = data.id ?? null
    this.src = data.src ?? ''
    this.mimeType = data.mime_type ?? ''
    this.customProperties = data.custom_properties ?? {}
    this.documentUrl = data.document_url ?? ''
  }
}
