export default {
  SET_ID: 'SET_ID',
  SET_NAME: 'SET_NAME',
  SET_PHONE: 'SET_PHONE',
  SET_EMAIL: 'SET_EMAIL',
  SET_BALANCE: 'SET_BALANCE',
  SET_ACCOUNT_TYPE: 'SET_ACCOUNT_TYPE',
  SET_ACCOUNT_MANAGER: 'SET_ACCOUNT_MANAGER',
  SET_COMPANY_NAME: 'SET_COMPANY_NAME',
  SET_COMPANY_ADDRESS: 'SET_COMPANY_ADDRESS',
  SET_COMPANY_SITE: 'SET_COMPANY_SITE',
  SET_VAT: 'SET_VAT',
  SET_ADDITIONAL_INFO: 'SET_ADDITIONAL_INFO',
  SET_AD_ACTIONS_NOTIFICATIONS: 'SET_AD_ACTIONS_NOTIFICATIONS',
  SET_CAMPAIGN_ACTIONS_NOTIFICATIONS: 'SET_CAMPAIGN_ACTIONS_NOTIFICATIONS',
  SET_CAMPAIGN_TOOLTIPS_NOTIFICATIONS: 'SET_CAMPAIGN_TOOLTIPS_NOTIFICATIONS',
  SET_TRAFFIC_PROVIDER_SUBSCRIPTION: 'SET_TRAFFIC_PROVIDER_SUBSCRIPTION',
  SET_REFERRAL_PROGRAM_PERCENT: 'SET_REFERRAL_PROGRAM_PERCENT',
  SET_IS_COMPANY_VERIFIED: 'SET_IS_COMPANY_VERIFIED',
  SET_VERIFICATION_STATUSES: 'SET_VERIFICATION_STATUSES',
  SET_TWO_FACTOR_AUTHENTICATION_METHOD: 'SET_TWO_FACTOR_AUTHENTICATION_METHOD',
  SET_RECURRING_PAYMENTS_CUSTOMER: 'SET_RECURRING_PAYMENTS_CUSTOMER',
  REMOVE_USER_MESSENGER: 'REMOVE_USER_MESSENGER',
  SET_USER_MESSENGERS: 'SET_USER_MESSENGERS',
  SET_FETCH_IS_LOADING: 'SET_FETCH_IS_LOADING',
  SET_SAVE_IS_LOADING: 'SET_SAVE_IS_LOADING',
  SET_ERRORS: 'SET_ERRORS',
  SET_SUBSCRIBE_TELEGRAM: 'SET_SUBSCRIBE_TELEGRAM',
  SET_FULL_MANAGE_STATUS: 'SET_FULL_MANAGE_STATUS',
  SET_ACTIONS: 'SET_ACTIONS'
}
