export default {
  install(_Vue) {
    const dashly = new _Vue({
      data() {
        return {
          inited: false
        }
      },
      methods: {
        openChat() {
          window.dashly.open()
        },
        async init(dashlySettings) {
          if (!this.inited) {
            await this.loadScript(dashlySettings)
            this.inited = true
          }
          window.dashly.auth(dashlySettings.userId, dashlySettings.userHash)
        },
        loadScript({ apiKey }) {
          return new Promise((resolve) => {
            function t(value) {
              // eslint-disable-next-line func-names
              return function () {
                // eslint-disable-next-line prefer-rest-params
                window.dashlyasync.push(value, arguments)
              }
            }
            if (typeof window.dashly === 'undefined') {
              const script = document.createElement('script')
              script.type = 'text/javascript'
              script.async = true
              script.src = 'https://cdn.dashly.app/api.min.js'
              document.getElementsByTagName('head')[0].appendChild(script)
              window.dashly = {}
              window.dashlyasync = []
              window.dashly.settings = {}
              for (
                let n = [
                    'connect',
                    'track',
                    'identify',
                    'auth',
                    'onReady',
                    'addCallback',
                    'removeCallback',
                    'trackMessageInteraction'
                  ],
                  a = 0;
                a < n.length;
                a += 1
              ) window.dashly[n[a]] = t(n[a])
            }
            window.dashly.connect(apiKey)
            resolve()
          })
        }
      }
    })

    _Vue.prototype.$dashly = dashly
  }
}
