<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-row class="ma-0" align-content="center" justify="center">
          <v-col class="d-flex justify-center" cols="12" xs="12">
            <v-img :src="getAdNetworkImages.theme_white_logo" height="60" width="200" contain />
          </v-col>
          <v-col cols="12">
            <v-progress-linear color="primary" indeterminate height="5" class="loading-auth" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Toolbar',
    computed: {
      ...mapGetters('settings', ['getAdNetworkImages'])
    }
  }
</script>

<style lang="scss" scoped>
  .loading-auth {
    max-width: 240px;
    margin: auto;
    border-radius: 5px;
  }
</style>
