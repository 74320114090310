const gtm = (w, d, s, l, i) => {
  w[l] = w[l] || []
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
  const j = d.createElement(s)
  const dl = l !== 'dataLayer' ? `&l=${l}` : ''
  j.async = true
  j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`
  d.head.appendChild(j)
}

export default {
  gtmCreate: gtm
}
