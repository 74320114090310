import { rotationsTypes } from '@clickadilla/components/constants/rotations-types.js'

export default class Rotation {
  hoursThreshold = null
  impressionsThreshold = null
  smartProgress = null
  winnerId = null
  type = null

  constructor(data) {
    if (!data) return
    // @TODO remove this after BE fixes Rotations
    if (data.type === rotationsTypes.SMART) {
      this.hoursThreshold = data.hours_threshold ?? null
      this.impressionsThreshold = data.impressions_threshold ?? null
      this.smartProgress = data.smart_progress ?? null
      this.winnerId = data.winner_id ?? null
    }

    this.type = data.type ?? null
  }
}
