import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
import http from '../http.js'
import urlFormatter from '../url-formatter.js'

const getURL = urlFormatter({
  list: '/api/private/v1/finances/statistics'
})

export default {
  list({ start, end }) {
    const endpoint = getURL('list')
    return http
      .get(endpoint, {
        params: {
          start,
          end
        }
      })
      .then((response) => response.data.data.map((item) => ({
        date: item.date,
        debit: toFixedByDefault(+item.debit),
        credit: toFixedByDefault(+item.credit)
      })))
  },
  getURL
}
