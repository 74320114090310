import http from '../http.js'

export default {
  async fetchAvailableCategoryGroups({ adFormatId, placementTypesIds }) {
    if (!adFormatId) {
      return []
    }

    this.fetchAvailableCategoryGroups.abort?.()
    const { data } = await http.get('/api/private/v1/available-categories', {
      params: {
        ad_format_id: adFormatId,
        placement_type_ids: placementTypesIds
      },
      cancelToken: new http.CancelToken((abort) => {
        this.fetchAvailableCategoryGroups.abort = abort
      })
    })

    return data.data
  }
}
