class CodeRequiredLoginError extends Error {
  constructor({ authenticationMethod, codeExpirationTime, codeSentAt }) {
    super('Two factor authenification code is required')
    this.name = 'CodeRequiredLoginError'
    this.authenticationMethod = authenticationMethod
    this.codeExpirationTime = codeExpirationTime
    this.codeSentAt = codeSentAt
  }
}

export default CodeRequiredLoginError
