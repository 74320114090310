import http from '../http.js'
import urlFormatter from '../url-formatter.js'

const getURL = urlFormatter({
  find: '/api/private/v1/cities'
})

export default {
  find({
    query = '', regionsIds = [], countriesIds = [], citiesIds = []
  }) {
    const endpoint = getURL('find')
    return http
      .post(endpoint, {
        query,
        region_ids: regionsIds,
        country_ids: countriesIds,
        city_ids: citiesIds
      })
      .then((response) => response.data.data)
  },
  getURL
}
