export default class CategoryGroup {
  id = null
  name = ''

  constructor(data) {
    if (!data) return

    this.id = data.id ?? null
    this.name = data.name ?? ''
  }
}
