import registerServiceWorker from '@/registerServiceWorker.js'
import createManifest from '@/createManifest.js'

const removeManifest = () => {
  const existingManifest = document.querySelector('link[rel="manifest"]')

  if (existingManifest) {
    existingManifest.remove()
  }
}

const unregisterServerWorkers = async () => {
  if ('serviceWorker' in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations()
    const unregistersTasks = []
    // eslint-disable-next-line no-restricted-syntax
    for (const registration of registrations) {
      unregistersTasks.push(registration.unregister())
    }

    await Promise.all(unregistersTasks)
  }
}

export default async function startPwa(enablePwa) {
  if (process.env.NODE_ENV === 'production') {
    removeManifest()
    await unregisterServerWorkers()

    if (!enablePwa) return

    createManifest()
    registerServiceWorker()
  }
}
