import axios from 'axios'
import * as Sentry from '@sentry/vue'
import { showErrorNotification } from '@/plugins/global-methods.js'
import router from '@/router.js'
import routeNames from '@/types/route-names.js'

/**
 * Handle http error.
 * @param {object} error - base error object
 * @param {?boolean} options.useNotice - flag for notice shown
 * @param {?string} options.noticeMessage - message for notice
 * @return {object}
 */

function handleErrors(error, options = { useNotice: true, noticeMessage: null }) {
  if (axios.isCancel(error)) {
    return {}
  }

  if (error.response?.status === 401) {
    router.push({ name: routeNames.LOGIN })
    return {}
  }

  const { useNotice, noticeMessage } = options
  const validationErrors = error.response?.data?.errors
  const hasValidationErrors = validationErrors && Object.values(validationErrors).length
  const validationErrorsMessage = error.response?.data?.error?.message
  const errorMessage = error.response?.data?.message

  if (hasValidationErrors) {
    return validationErrors
  }
  Sentry.withScope((scope) => {
    if (error.response) {
      scope.setTransactionName(`Response status: ${error.response.status}`)
    } else if (error.request) {
      scope.setTransactionName(`Request status: ${error.request.status}`)
    } else {
      scope.setTransactionName('Anything error')
    }
    scope.setExtra('Request ', error.request)
    scope.setExtra('Request __sentry_xhr__', error.request?.__sentry_xhr__)
    scope.setExtra('Request response', error.request?.response)
    Sentry.captureException(error)
  })

  requestAnimationFrame(() => {
    if (useNotice) {
      const text = noticeMessage || validationErrorsMessage || errorMessage || error.message
      showErrorNotification(text)
    }
  })

  return {}
}

export default handleErrors
