function createLink(icon) {
  if (!icon.href) return

  const link = document.createElement('link')
  link.rel = 'icon'
  link.sizes = icon.sizes
  link.href = icon.href
  document.head.appendChild(link)
}

export default function setFavicons(favicons) {
  createLink({ href: favicons.client_16x16, sizes: '16x16' })
  createLink({ href: favicons.client_32x32, sizes: '32x32' })
  createLink({ href: favicons.client_128x128, sizes: '128x128' })
}
