const state = {
  accountType: 'personal',
  name: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  companyName: '',
  valueAddedTax: '',
  companyAddress: '',
  companySite: '',
  additionalInfo: '',
  gRecaptchaResponse: '',
  selectedMessengers: [],

  errors: {}
}

export default state
