const state = {
  trafficChartIsLoading: false,
  premiumSitesIsLoading: false,
  traffic: [],
  isPremiumSites: false,
  trafficTypeId: null,
  selectRotationAdType: null,
  adFormatId: null,
  pricingModel: '',
  premiumSitesByFormat: [],
  premiumSitesIds: [],
  operatingSystemsIds: [],
  countriesIds: [],
  countryPreset: []
}

export default state
