<template>
  <component :is="$route.meta.layoutName" />
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import Loader from '@/components/Loader.vue'
  import DefaultLayout from '@/layouts/DefaultLayout.vue'
  import MainLayouts from '@/layouts/MainLayouts.vue'
  import EmptyLayout from '@/layouts/EmptyLayout.vue'

  export default {
    components: {
      Loader,
      DefaultLayout,
      MainLayouts,
      EmptyLayout
    },
    computed: {
      ...mapState('settings', ['adNetwork']),
      title() {
        const routeTitle = this.$route.meta.title ? ` -  ${this.$route.meta.title}` : ''
        return this.adNetwork.name + routeTitle
      },
      description() {
        const routeDescription = this.$route.meta.description ? ` - ${this.$route.meta.description}` : ''
        return this.adNetwork.name + routeDescription
      }
    },
    watch: {
      title: {
        immediate: true,
        handler(newTitle) {
          document.title = newTitle
        }
      },
      description: {
        immediate: true,
        handler(newDescription) {
          const metaTag = document.querySelector('meta[name="description"]')
          metaTag.setAttribute('content', newDescription)
        }
      },
      adNetwork() {
        this.setVuetifyColor(this.$vuetify)
      }
    },
    methods: {
      ...mapActions('settings', ['setVuetifyColor'])
    }
  }
</script>
