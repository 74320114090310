import http from '../http.js'
import urlFormatter from '../url-formatter.js'

const getURL = urlFormatter({
  srcById: '/api/private/v1/upload-file/{id}/web-path'
})

export default {
  srcById(id) {
    const endpoint = getURL('srcById', { id })
    return http.get(endpoint).then((response) => response.data)
  },
  srcByIdMedia: async (id) => {
    const { data } = await http.get(`/api/private/v1/media/${id}`)
    return data.data
  },
  getURL
}
