export default class InPageSkinGroup {
  id = null
  name = ''
  inPageSkins = []

  constructor(data) {
    if (!data) return

    this.id = data.id ?? null
    this.name = data.name ?? ''
    this.inPageSkins = data.in_page_skins?.map((skin) => ({
      id: skin.id ?? null,
      name: skin.name ?? '',
      tdsId: skin.tds_id ?? null
    })) ?? []
  }
}
