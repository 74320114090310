export default function createCalendarLimits(calendarLimitsObj) {
  const calendarLimits = JSON.parse(JSON.stringify(Array(24).fill(Array(7).fill(''))))

  if (calendarLimitsObj && calendarLimitsObj.constructor === Object) {
    if (Object.keys(calendarLimitsObj).length) {
      Object.entries(calendarLimitsObj).forEach(([hourIndex, daysObj]) => {
        Object.entries(daysObj).forEach(([dayIndex, value]) => {
          calendarLimits[+hourIndex][+dayIndex] = value
        })
      })
    }
  }

  return calendarLimits
}
