<template>
  <v-list class="py-0" flat>
    <v-tooltip right color="primary">
      <template #activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <v-list-item
            :class="[{ 'px-0 justify-center': isMiniSidebarShown }, 'mx-4 py-0 px-3']"
            v-bind="isTooltipShown(attrs)"
            v-on="isTooltipShown(on)"
            @click="downloadPwa()"
          >
            <v-list-item-action
              :class="['d-flex justify-center align-center', { 'mr-3': !isMiniSidebarShown }]"
            >
              <v-icon
                size="18"
                :class="{ 'opacity-60': !hover }"
                :color="hover ? 'primary' : 'white'"
              >
                mdi-download
              </v-icon>
            </v-list-item-action>
            <v-list-item-title :class="{ 'opacity-60': !hover }" class="custom-title white--text">
              PWA
            </v-list-item-title>
          </v-list-item>
        </v-hover>
      </template>

      <span>PWA</span>
    </v-tooltip>
  </v-list>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'InstallPwa',
    computed: {
      ...mapState('settings', ['isMiniSidebarShown'])
    },
    methods: {
      downloadPwa() {
        this.$pwa.prompt()
      },
      isTooltipShown(paramTooltip) {
        return this.isMiniSidebarShown && paramTooltip
      }
    }
  }
</script>
