import { showErrorNotification } from '@/plugins/global-methods.js'

export default {
  install(_Vue) {
    const tawk = new _Vue({
      data() {
        return {
          inited: false,
          tawkApi: {}
        }
      },
      methods: {
        setUser({ name, email }) {
          window.Tawk_API = window.Tawk_API || {}
          window.Tawk_API.onLoad = () => {
            window.Tawk_API.setAttributes(
              {
                name,
                email
              },
              (error) => {
                if (error) {
                  showErrorNotification(error)
                }
              }
            )
          }
        },
        toggleChat() {
          window.Tawk_API.toggle()
        },
        async init(tawkSettings) {
          if (!this.inited) {
            await this.loadScript(tawkSettings)
            this.setUser(tawkSettings)
            this.inited = true
          }
        },
        loadScript({ widgetId, accountKey }) {
          return new Promise((resolve) => {
            const script = document.createElement('script')
            script.async = true
            script.src = `https://embed.tawk.to/${accountKey}/${widgetId}`
            const firstScript = document.getElementsByTagName('script')[0]
            firstScript.parentNode.insertBefore(script, firstScript)
            script.onload = resolve
          })
        }
      }
    })

    _Vue.prototype.$tawk = tawk
  }
}
