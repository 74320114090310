import axios from 'axios'
import { stringify } from 'qs'
import Vue from 'vue'
import settings from '@/settings.js'

const instance = axios.create({
  baseURL: settings.apiHost,
  paramsSerializer: (params) => stringify(params)
})

instance.CancelToken = axios.CancelToken

instance.interceptors.request.use(
  (config) => {
    const token = Vue.prototype.$auth.getToken()
    config.headers = {
      ...config.headers,
      ...(token && { Authorization: `Bearer ${token}` })
    }
    return config
  },
  (error) => Promise.reject(error)
)
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      Vue.prototype.$auth.removeToken()
    }
    return Promise.reject(error)
  }
)

export default instance
