export default {
  install(_Vue) {
    const intercom = new _Vue({
      data() {
        return {
          inited: false,
          actionsQueue: []
        }
      },
      methods: {
        show() {
          this.callIntercom('show')
        },
        showNewMessage() {
          this.callIntercom('showNewMessage')
        },
        hide() {
          this.callIntercom('hide')
        },
        update(...args) {
          this.callIntercom('update', ...args)
        },
        shutdown() {
          this.callIntercom('shutdown')
        },
        callIntercom(...args) {
          if (this.inited) {
            window.Intercom(...args)
          } else {
            this.actionsQueue.push(() => window.Intercom(...args))
          }
        },
        execActionsQueue() {
          this.actionsQueue.forEach((callback) => {
            callback()
            this.actionsQueue.shift()
          })
        },
        async init(intercomSettings) {
          window.intercomSettings = intercomSettings
          if (!this.inited) {
            await this.loadScript()
            this.inited = true
          } else {
            this.callIntercom('reattach_activator')
            this.update()
          }
          this.execActionsQueue()
        },
        loadScript() {
          return new Promise((resolve) => {
            const script = document.createElement('script')
            script.async = true
            script.src = `https://widget.intercom.io/widget/${window.intercomSettings.app_id}`
            const firstScript = document.getElementsByTagName('script')[0]
            firstScript.parentNode.insertBefore(script, firstScript)
            script.onload = resolve
          })
        }
      }
    })

    _Vue.prototype.$intercom = intercom
  }
}
