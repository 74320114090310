import Media from '@/services/classes/Media.js'

export default class BannerCreative {
  id = null
  image = new Media()
  isActive = true
  package = new Media()
  url = ''

  constructor(data) {
    if (!data) return

    this.id = data.id ?? null
    this.image = new Media(data.image)
    this.isActive = data.is_active ?? true
    this.package = new Media(data.package)
    this.url = data.url ?? ''
  }
}
