<template>
  <v-slide-x-transition>
    <v-alert
      class="text-center text-body-2"
      color="warning"
      dismissible
      dark
      @input="closeVerificationBanner()"
    >
      {{ $t('main.email_warning.not_verified') }}
      <a
        class="text-decoration-underline white--text font-weight-bold"
        @click="confirmEmail()"
      >
        {{ $t('main.email_warning.send_again') }}
      </a>
      {{ $t('main.email_warning.resend_it') }}
    </v-alert>
  </v-slide-x-transition>
</template>

<script>
  import personalRepository from '@/services/repositories/personal-repository.js'

  export default {
    name: 'EmailVerificationBanner',
    data() {
      return {
        isLoading: false
      }
    },
    methods: {
      async confirmEmail() {
        if (this.isLoading) return

        try {
          this.isLoading = true
          await personalRepository.sendEmailConfirmation()
          this.closeVerificationBanner()
          this.$showSuccessNotification(this.$t('main.email_sent'))
        } catch (error) {
          this.$showErrorNotification(error.message)
        }
        this.isLoading = false
      },
      closeVerificationBanner() {
        this.$emit('close-banner')
      }
    }
  }
</script>
