import { networksRepository } from '@/services/repository-factory.js'

export default {
  namespaced: true,
  state: {
    network: {}
  },
  getters: {},
  mutations: {
    SET_NETWORK: (state, val) => {
      state.network = val
    }
  },
  actions: {
    fetchNetwork({ commit }) {
      return networksRepository.show().then((network) => {
        commit('SET_NETWORK', network || {})
        return network
      })
    }
  }
}
