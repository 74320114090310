import Media from '@/services/classes/Media.js'

export default class PushCreative {
  body = ''
  buttonOne = ''
  buttonTwo = ''
  icon = new Media()
  id = null
  image = new Media()
  isActive = true
  title = ''
  url = ''
  priority = null

  constructor(data) {
    if (!data) return

    this.body = data.body ?? ''
    this.buttonOne = data.button_one ?? ''
    this.buttonTwo = data.button_two ?? ''
    this.icon = new Media(data.icon)
    this.id = data.id ?? null
    this.image = new Media(data.image)
    this.isActive = data.is_active ?? true
    this.title = data.title ?? ''
    this.url = data.url ?? ''
    this.priority = data.priority ?? null
  }
}
